#page-login,
#page-signup {
  background: #eeecec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.invalid-feedback {
  display: inherit !important;
}
