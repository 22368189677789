body {
  background: #1e1e1e;
  width: auto;
}
html,
body {
  overflow-x: hidden;
}
.custom-main-cls {
  padding-left: 110px;
  padding-right: 110px;
  margin-bottom: 100px !important;
}
.dropdown-custom-cls {
  color: #ebebeb;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 37px;
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
}
.custom-nav-ul li {
  padding-left: 24px;
  padding-right: 24px;
}

.custom-nav-ul li a {
  color: #ebebeb;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.dropdown-custom-cls:hover {
  color: #18dfb4;
}
.dropdown-custom-cls:focus {
  color: #18dfb4;
}
.dropdown-toggle::after {
  vertical-align: middle;
}
.custom-nav-ul li a:hover {
  color: #18dfb4;
}
.custom-nav-ul li a:focus {
  color: #18dfb4;
}

.custom-btn-cls {
  background: linear-gradient(90deg, #018efe 1.32%, #6ec0ff 100%);
  box-shadow: 0px 10px 45px #003158;
  border-radius: 100px;
  padding: 12px 62px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  border: none;
}
#offcanvasNavbar {
  width: 250px;
  background: rgba(5, 6, 22, 0.9);
  border-right: 2px solid rgba(242, 243, 245, 0.06);
  backdrop-filter: none !important;
  border-radius: 0px 40px 40px 0px;
}
.offcanvas-header {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.offcanvas-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #fff;
}
.offcanvas-header .btn-close {
  background: url('/images/back-arrow.png') no-repeat;
  margin: 0;
  padding: 0 30px 0 0;
}
.banner-subheading-cls {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #5699ff;
}
.banner-heading-cls {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
  color: #ffffff;
}
.img-background {
  width: 379.96px;
  height: 346.96px;
  margin: auto;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #9fb9ff;
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
  position: relative;
  bottom: -50px;

  z-index: 9999;
}

.custom-top-banner-gradient .container {
  position: relative;
}

.img-background-left {
  width: 379.96px;
  height: 346.96px;
}
.img-background-right {
  width: 379.96px;
  height: 346.96px;
}
.img-background img {
  margin-top: -50px;
}
.buttons-background {
  width: 459.15px;
  height: 182.91px;
  margin: auto;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #9fb9ff;
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
  text-align: center;
  z-index: 1;
  position: relative;
}

.custom-banner-btn-1 {
  background: linear-gradient(90deg, #018efe 1.32%, #6ec0ff 100%);
  box-shadow: 0px 10px 45px #003158;
  border-radius: 100px;
  padding: 12px 62px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  border: none;
}
.custom-banner-btn-2 {
  padding: 12px 62px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #9fb9ff;
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
}
.p-cls {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #6e7aa2;
}
.p-cls-1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #6e7aa2;
}
.button-banner-left {
  background: linear-gradient(90deg, #30e3ff 1.32%, #3ffad7 100%);
  box-shadow: 0px 10px 45px rgba(50, 229, 251, 0.4);
  border-radius: 100px;
  padding: 12px 62px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: none;
}
.custom-top-banner-gradient a button[type='button'] {
  font-weight: 900;
  font-size: 18px;
}
.h2-custom-cls {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  color: #ffffff;
}
.h2-custom-cls span {
  background: linear-gradient(180deg, #18dfb4 0%, #0543ce 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.work-section .card {
  background: #131322;
  border-radius: 20px;
}
.card h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.card-1:before {
  display: inline-block;
  content: url('/images/plan.png');
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  background: linear-gradient(233.07deg, #68f1a8 16.8%, #0a9d4f 81.59%);
  box-shadow: 0px 10px 45px #00582a;
  padding: 15px 22px;
  position: relative;
  left: 40%;
  top: -30px;
}

.card-2:before {
  display: inline-block;
  content: url('/images/document.png');
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  background: linear-gradient(233.07deg, #b880ff 16.8%, #6e00b1 81.59%);
  box-shadow: 0px 10px 45px #440079;
  padding: 15px 22px;
  position: relative;
  left: 40%;
  top: -30px;
}
.card-3:before {
  display: inline-block;
  content: url('/images/collaboration.png');
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  background: linear-gradient(233.07deg, #ffca8b 16.8%, #d07000 81.59%);
  box-shadow: 0px 10px 45px #552e00;
  padding: 15px 22px;
  position: relative;
  left: 40%;
  top: -30px;
}

.card-1 h5 {
  color: #65efa6;
}
.card-2 h5 {
  color: #b87fff;
}
.card-3 h5 {
  color: #fec786;
}
.card p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}
.card a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #131322;
  border: 1px solid #ffffff;
  border-radius: 100px;
  padding: 12px 62px;
}
.card a:hover {
  background: linear-gradient(90deg, #018efe 1.32%, #6ec0ff 100%);
  box-shadow: 0px 10px 45px #003158;
}
.custom-bg-use {
  background-image: url('/images/use.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-bg-works {
  background-image: url('/images/works.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-top-banner-gradient {
  padding-top: 20px;
  padding-bottom: 100px;
}
.custom-top-banner-gradient {
}

.hero-bg {
  background-image: url(/images/gradient-final.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 1061px;
  height: 1089px;
  top: -230px;
  z-index: -1;
  right: -181px;
}

.custom-top-banner-gradient .navbar,
.buttons-background {
  z-index: 99999;
}

.buttons-background {
  z-index: 999;
}

/* .custom-banner-img-bg{
    background-image: url("/images/banner-bg-green.svg");
    background-position: left 0%; 
    background-repeat: no-repeat;
    background-size: cover; 
    background-size: 375px 375px;
} */
.custom-objects-img-bg {
  /* background-image: url("/images/Blur1.png");
    background-position: right -40px;
    background-repeat: no-repeat;
    max-width: 1320px;
    margin: 0 auto;*/

  /* background-size: 675px 675px; */
}

.custom-objects-img-bg .blue-blur {
  background-image: url('/images/Blur1.png');
  background-position: right -35px;
  background-repeat: no-repeat;
}

.bg-blue {
  background-image: url('/images/Blur1.png');
  background-position: right -35px;
  background-repeat: no-repeat;
  position: absolute;
  top: -70px;
  width: 480px;
  height: 461px;
}

.bg-green {
  background-image: url(/images/blur-green.png);
  background-repeat: no-repeat;
  background-position: -29px 11px;
  position: absolute;
  top: -118px;
  width: 480px;
  height: 461px;
  left: -85px;
}

.custom-bg-use .col-md-3,
.custom-objects-img-bg .col-md-4,
.custom-seo-img-bg .col-lg-4 {
  position: relative !important;
}

.custom-bg-use .robofly,
.custom-objects-img-bg img,
.custom-seo-img-bg img {
  position: relative;
  z-index: 999;
}

.custom-seo-img-bg {
  /* background-image: url("/images/blur-green.png");
    background-position: left 0px; 
    background-repeat: no-repeat;
    max-width: 1320px;
    margin: 0 auto;*/

  /* background-size: 375px 375px; */
}

.custom-seo-img-bg .green-blur {
  background-image: url('/images/blur-green.png');
  background-repeat: no-repeat;
  background-position: -29px 11px;
}

.fix-pos-strategy {
  position: relative;
  top: -1px;
  left: 81px;
}

.fix-my-pos {
  position: relative;
  left: -74px;
}

.custom-h4-cls {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */

  text-transform: capitalize;

  color: #ffffff;
}
.use-paige-ul-cls {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  /* or 200% */

  color: #6e7aa2;
}
.use-paige-btn {
  background: linear-gradient(90deg, #018efe 1.32%, #6ec0ff 100%);
  box-shadow: 0px 10px 45px #003158;
  border-radius: 100px;
  padding: 12px 62px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: none;
}

/* Slider CSS */

.slider-section-cls {
  height: 96px;
  background: #0245d1;
}
.slider-text-cls1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;
  /* identical to box height, or 150% */

  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
  @media only screen and (min-width: 1200px) {
    font-size: 32px;
  }
}
.slider-text-cls2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  @media only screen and (min-width: 1200px) {
    font-size: 32px;
  }
  line-height: 48px;
  text-align: center;
  text-transform: capitalize;
  background: linear-gradient(180deg, #18dfb4 0%, #0543ce 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/*----------Blog Post css-----------*/
#post-wrapper .u-blog-post {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}
#post-wrapper .blog-heading a {
  font-family: 'Poppins';
  /* font-style: normal; */
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
}
#post-wrapper .blog-heading {
  font-size: 16px;
  line-height: 26px;
}
#post-wrapper .blog-metadata .post-meta-date {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  text-decoration: none;
  text-transform: capitalize;
  color: #ffffff;
}

#post-wrapper .container-layout-post {
  padding: 25px 20px 25px 10px;
}
#post-wrapper .post-box {
  background: #131322;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
}

#post-wrapper .post-content p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 15px;
  color: #6e7aa2;
}
#post-wrapper .h2-custom-cls {
  text-align: center;
  padding-bottom: 65px;
}
#post-wrapper .post-box .footer-btn a {
  color: #fff;
  text-decoration: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  background: linear-gradient(90deg, #018efe 1.32%, #6ec0ff 100%);
  box-shadow: 0px 10px 45px #003158;
  border-radius: 100px;
}
.section-wrapper {
  padding-top: 30px;
  padding-bottom: 125px;
  margin: 0 auto;
}
/*
#post-wrapper .post-img-col-1:before{
   

    display: inline-block;
    content: "";
    font-size: 30px;
    color: #fff;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    background: linear-gradient(233.07deg, #68F1A8 16.8%, #0A9D4F 81.59%);
    box-shadow: 0px 10px 45px #00582A;
    padding-top: 10px;
    position: absolute;
    left: 15%;
    top: -53px;
    box-shadow: 0px 10px 45px #62EDA4;
}
*/
#post-wrapper .post-img {
  height: 348px;
  border-radius: 20px 0 0 20px;
}
#post-wrapper .post-img-col-1 svg,
#post-wrapper .post-img-col-2 svg,
#post-wrapper .post-img-col-3 svg {
  display: inline-block;
  content: url(/images/image-file.png);
  padding-top: 18px;
  color: #fff;
  font-size: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  left: 15%;
  top: -40px;
  box-shadow: 0px 10px 45px #62eda4;
}

#post-wrapper .post-img-col-1 svg {
  background: linear-gradient(233.07deg, #68f1a8 16.8%, #0a9d4f 81.59%);
  box-shadow: 0px 10px 45px #00582a;
}

#post-wrapper .post-img-col-2 svg {
  background: linear-gradient(233.07deg, #ffca8b 16.8%, #d07000 81.59%);
  box-shadow: 0px 10px 45px #ffc889;
}
#post-wrapper .post-img-col-3 svg {
  background: linear-gradient(233.07deg, #b880ff 16.8%, #6e00b1 81.59%);
  box-shadow: 0px 10px 45px #b87fff;
}

/* Testimonial slider css */

.demo {
  background: #141423;
  padding: 70px 0px !important;
  margin: auto;
}
#testimonial-slider {
  margin: auto;
  width: 900px;
}
.testimonial .description {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  font-family: 'poppins';
  color: #6e7aa1;
  position: relative;
  margin-top: 10px;
  text-align: center;
}

.testimonial .title {
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  font-family: 'poppins';
  color: #fff;
}
.testimonial .post {
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  font-family: 'poppins';
  color: #6c727f;
}
.owl-buttons {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 40%;
  left: 0;
}

.text-style {
  margin: 0px 300px !important;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  font-family: 'poppins';
  color: #6e7aa1;
  position: relative;
  margin-top: 10px;
  text-align: center;
}
hr {
  width: 100px;
  color: #fff;
  border-top: 2px solid #fff;
  text-align: center;
  margin: auto;
  margin-block: 35px;
}

/*foooter css*/

.main-footer {
  background: #050616;
  padding-top: 100px;
  padding-bottom: 20px;
}
.main-footer .list-group {
  background: transparent !important;
}
.main-footer .list-group .list-group-item {
  background: transparent !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'poppins';
  color: #fff !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-radius: 0px !important;
}
.list-group-horizontal > .list-group-item:not(:last-child) {
  border-radius: 0px !important;
  border-right: 2px solid #fff !important;
  height: 20px !important;
  vertical-align: middle;
  margin-top: 50px;
}
.list-design {
  margin-top: 50px;
}
.main-footer .copyright-text {
  border-top: 1px solid #6e7aa1;
  padding-top: 30px;
  margin-top: 40px;
}
.main-footer .text p {
  color: #fff;
  font-size: 16px;
  font-family: 'poppins';
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0px;
}
.blog-heading {
  text-align: left;
}

.paige-btn-div button {
  color: #fff !important;
}

/*Animation css*/
.robofly {
  animation-name: robofly;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes robofly {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 30px);
  }
  to {
    transform: translate(0, -0px);
  }
}

#testimonial-slider .owl-dots button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: url(/images/miraa.png) no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out 0s;
}

#testimonial-slider .owl-dots button:nth-child(2) {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: url(/images/mira.png) no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out 0s;
}

#testimonial-slider .owl-dots button:nth-child(3) {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: url(/images/miraa.png) no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out 0s;
}

#testimonial-slider .owl-dots button.active {
  border: 4px solid #58fffe;
  opacity: 1;
}

#testimonial-slider .owl-dots button {
  margin: 5px 15px !important;
}

#testimonial-slider .testimonial {
  margin-bottom: 30px !important;
}
.foot-style {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  font-family: 'poppins';
  color: #6e7aa1;
  position: relative;
  margin-top: 25px;
  text-align: center;
  border-top: 1px solid #6e7aa1;
  padding-top: 21px;
}

/*--------------media query--------------*/

@media only screen and (max-width: 1399px) {
  #post-wrapper .u-blog-post {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  #testimonial-slider {
    width: 720px !important;
  }
  .text-style {
    margin: 0px !important;
  }
  #post-wrapper .post-img {
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
  #post-wrapper .post-img-col-1:before,
  #post-wrapper .post-img-col-2:before,
  #post-wrapper .post-img-col-3:before {
    left: 14%;
  }
  #post-wrapper .post-box {
    /* grid-template-columns: repeat(1,1fr);*/
  }
  #post-wrapper .container-layout-post {
    padding: 25px 20px 45px 10px;
  }
  #post-wrapper .u-blog-post {
    grid-row-gap: 100px;

    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  #offcanvasNavbar .nav-custom-btn-cls {
    position: absolute;
    bottom: 30px;
    left: 15%;
  }

  #post-wrapper .post-box .footer-btn a {
    padding: 12px 22px;
  }
}

@media only screen and (max-width: 768px) {
  #post-wrapper .post-box .footer-btn a {
    font-size: 10px !important;
  }

  #post-wrapper .post-img-col-1:before,
  #post-wrapper .post-img-col-2:before,
  #post-wrapper .post-img-col-3:before {
    left: 14% !important;
  }
}
@media only screen and (max-width: 767px) {
  .custom-btn-cls {
    padding: 8px 39px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }
  .row-banner,
  .row-paige-does {
    flex-direction: column-reverse;
  }
  .row-use-paige :nth-child(1) {
    order: 2;
  }
  .row-use-paige :nth-child(2) {
    order: 2;
  }
  .row-use-paige :nth-child(3) {
    order: 3;
  }
  .row-use-paige :nth-child(4) {
    order: 1;
  }
  .banner-subheading-cls {
    font-size: 12px;
    line-height: 18px;
  }

  #post-wrapper .post-box .footer-btn a {
    font-size: 11px !important;
    padding: 12px 20px;
  }

  .row-banner,
  .row-paige-does,
  .row-seo {
    text-align: center;
  }
  .banner-heading-cls {
    font-size: 30px;
    line-height: 40px;
  }
  .h2-custom-cls {
    font-size: 30px;
    line-height: 45px;
  }
  .p-cls {
    font-size: 14px;
    line-height: 22px;
  }
  .p-cls-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .use-paige-ul-cls {
    font-size: 14px;
    line-height: 40px;
  }
  .custom-h4-cls {
    font-size: 24px;
    line-height: 24px;
  }
  .img-background {
    background: transparent;
    border: none;
    backdrop-filter: blur(1px);
  }
  .buttons-background {
    display: none !important;
  }
  .paige-btn-div {
    display: flex;
    justify-content: center;
  }
  .testimonial-body h2 {
    font-size: 30px;
    line-height: 45px;
  }
  .testimonial-body p {
    font-size: 14px;
    line-height: 22px;
  }
  #post-wrapper .post-box {
    /* grid-template-columns: repeat(1,1fr);*/
  }
  #post-wrapper .u-blog-post {
    grid-row-gap: 100px;

    grid-template-columns: repeat(1, 1fr);
  }
  #testimonial-slider {
    width: 320px !important;
  }
  .text-style {
    margin: 0px !important;
  }
  #post-wrapper .post-box {
    /*   grid-template-columns: repeat(1,1fr);*/
  }
  #post-wrapper .u-blog-post {
    grid-row-gap: 100px;

    grid-template-columns: repeat(1, 1fr);
  }
  .slider-text-cls1,
  .slider-text-cls2 {
    font-size: 16px;
    line-height: 26px;
  }
  #offcanvasNavbar .nav-custom-btn-cls {
    left: 25%;
  }
}
@media only screen and (max-width: 479px) {
  .owl-buttons {
    bottom: 30%;
  }

  .img-background {
    width: auto;
    height: auto;
  }
}
