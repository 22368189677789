
.products {
  display: flex;
}

.product {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 34px 36px 28px;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  p {
    opacity: 0.5;
    font-size: 14px;
  }
  span {
    display: flex;
    align-items: center;

    b {
      font-weight: 700;
      font-size: 36px;
      margin-right: 4px;
    }
    small {
      display: inline-block;
      width: 60px;
      opacity: .5;
      font-size: 13px;
    }
  }

  &.highlight {
    background-color: hsla(0,0%,10%,.05);
    border: 1px solid hsla(0,0%,10%,.1);
  }
}

@media only screen and (max-width: 900px) {
  .products {
    flex-direction: column;
  }
}
