@charset "UTF-8";
.site-main .menu-navigation-container {
  overflow: visible;
}
.elementor-item:after,
.elementor-item:before {
  display: block;
  position: absolute;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
}
.elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  opacity: 0;
}
.elementor-item-active:after,
.elementor-item-active:before,
.elementor-item.highlighted:after,
.elementor-item.highlighted:before,
.elementor-item:focus:after,
.elementor-item:focus:before,
.elementor-item:hover:after,
.elementor-item:hover:before {
  transform: scale(1);
}
.e--pointer-double-line .elementor-item:after,
.e--pointer-double-line .elementor-item:before,
.e--pointer-overline .elementor-item:after,
.e--pointer-overline .elementor-item:before,
.e--pointer-underline .elementor-item:after,
.e--pointer-underline .elementor-item:before {
  height: 3px;
  width: 100%;
  left: 0;
  background-color: #55595c;
  z-index: 2;
}
.e--pointer-double-line.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-double-line.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-overline.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-overline.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-underline.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-underline.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  height: 0;
  width: 0;
  left: 50%;
}
.e--pointer-double-line.e--animation-drop-out
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-overline.e--animation-drop-out
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-underline.e--animation-drop-out
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  top: 10px;
}
.e--pointer-double-line.e--animation-drop-out
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-overline.e--animation-drop-out
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-underline.e--animation-drop-out
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after {
  bottom: 10px;
}
.e--pointer-double-line.e--animation-drop-in
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-overline.e--animation-drop-in
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-underline.e--animation-drop-in
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  top: -10px;
}
.e--pointer-double-line.e--animation-drop-in
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-overline.e--animation-drop-in
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-underline.e--animation-drop-in
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after {
  bottom: -10px;
}
.e--pointer-double-line.e--animation-none,
.e--pointer-double-line.e--animation-none .elementor-item,
.e--pointer-double-line.e--animation-none .elementor-item:after,
.e--pointer-double-line.e--animation-none .elementor-item:before,
.e--pointer-double-line.e--animation-none .elementor-item:focus,
.e--pointer-double-line.e--animation-none .elementor-item:hover,
.e--pointer-double-line.e--animation-none:after,
.e--pointer-double-line.e--animation-none:before,
.e--pointer-double-line.e--animation-none:focus,
.e--pointer-double-line.e--animation-none:hover,
.e--pointer-overline.e--animation-none,
.e--pointer-overline.e--animation-none .elementor-item,
.e--pointer-overline.e--animation-none .elementor-item:after,
.e--pointer-overline.e--animation-none .elementor-item:before,
.e--pointer-overline.e--animation-none .elementor-item:focus,
.e--pointer-overline.e--animation-none .elementor-item:hover,
.e--pointer-overline.e--animation-none:after,
.e--pointer-overline.e--animation-none:before,
.e--pointer-overline.e--animation-none:focus,
.e--pointer-overline.e--animation-none:hover,
.e--pointer-underline.e--animation-none,
.e--pointer-underline.e--animation-none .elementor-item,
.e--pointer-underline.e--animation-none .elementor-item:after,
.e--pointer-underline.e--animation-none .elementor-item:before,
.e--pointer-underline.e--animation-none .elementor-item:focus,
.e--pointer-underline.e--animation-none .elementor-item:hover,
.e--pointer-underline.e--animation-none:after,
.e--pointer-underline.e--animation-none:before,
.e--pointer-underline.e--animation-none:focus,
.e--pointer-underline.e--animation-none:hover {
  transition-duration: 0s;
}
.e--pointer-double-line .elementor-item:before,
.e--pointer-overline .elementor-item:before {
  content: '';
  top: 0;
}
.e--pointer-double-line.e--animation-slide
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before,
.e--pointer-overline.e--animation-slide
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  width: 10px;
  left: -20px;
}
.e--pointer-double-line .elementor-item:after,
.e--pointer-underline .elementor-item:after {
  content: '';
  bottom: 0;
}
.e--pointer-double-line.e--animation-slide
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after,
.e--pointer-underline.e--animation-slide
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):after {
  width: 10px;
  inset-inline-start: 100%;
}
.e--pointer-framed .elementor-item:after,
.e--pointer-framed .elementor-item:before {
  background: transparent;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 3px solid #55595c;
}
.e--pointer-framed .elementor-item:before {
  content: '';
}
.e--pointer-framed.e--animation-grow
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  transform: scale(0.75);
}
.e--pointer-framed.e--animation-shrink
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  transform: scale(1.25);
}
.e--pointer-framed.e--animation-grow .elementor-item:before,
.e--pointer-framed.e--animation-shrink .elementor-item:before {
  transition: opacity 0.2s, transform 0.4s;
}
.e--pointer-framed.e--animation-draw .elementor-item:after,
.e--pointer-framed.e--animation-draw .elementor-item:before {
  width: 3px;
  height: 3px;
}
.e--pointer-framed.e--animation-draw .elementor-item:before {
  border-width: 0 0 3px 3px;
  transition: width 0.1s 0.2s, height 0.1s 0.3s, opacity 0.12s 0.22s;
}
.e--pointer-framed.e--animation-draw .elementor-item:after {
  content: '';
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  border-width: 3px 3px 0 0;
  transition: width 0.1s, height 0.1s 0.1s, opacity 0.02s 0.18s;
}
.e--pointer-framed.e--animation-draw .elementor-item-active:after,
.e--pointer-framed.e--animation-draw .elementor-item-active:before,
.e--pointer-framed.e--animation-draw .elementor-item.highlighted:after,
.e--pointer-framed.e--animation-draw .elementor-item.highlighted:before,
.e--pointer-framed.e--animation-draw .elementor-item:focus:after,
.e--pointer-framed.e--animation-draw .elementor-item:focus:before,
.e--pointer-framed.e--animation-draw .elementor-item:hover:after,
.e--pointer-framed.e--animation-draw .elementor-item:hover:before {
  width: 100%;
  height: 100%;
}
.e--pointer-framed.e--animation-draw .elementor-item-active:before,
.e--pointer-framed.e--animation-draw .elementor-item.highlighted:before,
.e--pointer-framed.e--animation-draw .elementor-item:focus:before,
.e--pointer-framed.e--animation-draw .elementor-item:hover:before {
  transition: opacity 0.02s, height 0.1s, width 0.1s 0.1s;
}
.e--pointer-framed.e--animation-draw .elementor-item-active:after,
.e--pointer-framed.e--animation-draw .elementor-item.highlighted:after,
.e--pointer-framed.e--animation-draw .elementor-item:focus:after,
.e--pointer-framed.e--animation-draw .elementor-item:hover:after {
  transition: opacity 0.02s 0.2s, height 0.1s 0.2s, width 0.1s 0.3s;
}
.e--pointer-framed.e--animation-corners .elementor-item:after,
.e--pointer-framed.e--animation-corners .elementor-item:before {
  width: 3px;
  height: 3px;
}
.e--pointer-framed.e--animation-corners .elementor-item:before {
  border-width: 3px 0 0 3px;
}
.e--pointer-framed.e--animation-corners .elementor-item:after {
  content: '';
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  border-width: 0 3px 3px 0;
}
.e--pointer-framed.e--animation-corners .elementor-item-active:after,
.e--pointer-framed.e--animation-corners .elementor-item-active:before,
.e--pointer-framed.e--animation-corners .elementor-item.highlighted:after,
.e--pointer-framed.e--animation-corners .elementor-item.highlighted:before,
.e--pointer-framed.e--animation-corners .elementor-item:focus:after,
.e--pointer-framed.e--animation-corners .elementor-item:focus:before,
.e--pointer-framed.e--animation-corners .elementor-item:hover:after,
.e--pointer-framed.e--animation-corners .elementor-item:hover:before {
  width: 100%;
  height: 100%;
  transition: opacity 2ms, width 0.4s, height 0.4s;
}
.e--pointer-framed.e--animation-none,
.e--pointer-framed.e--animation-none .elementor-item,
.e--pointer-framed.e--animation-none .elementor-item:after,
.e--pointer-framed.e--animation-none .elementor-item:before,
.e--pointer-framed.e--animation-none .elementor-item:focus,
.e--pointer-framed.e--animation-none .elementor-item:hover,
.e--pointer-framed.e--animation-none:after,
.e--pointer-framed.e--animation-none:before,
.e--pointer-framed.e--animation-none:focus,
.e--pointer-framed.e--animation-none:hover {
  transition-duration: 0s;
}
.e--pointer-background .elementor-item:after,
.e--pointer-background .elementor-item:before {
  content: '';
  transition: 0.3s;
}
.e--pointer-background .elementor-item:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #55595c;
  z-index: -1;
}
.e--pointer-background .elementor-item-active,
.e--pointer-background .elementor-item.highlighted,
.e--pointer-background .elementor-item:focus,
.e--pointer-background .elementor-item:hover {
  color: #fff;
}
.e--pointer-background.e--animation-grow .elementor-item:before {
  transform: scale(0.5);
}
.e--pointer-background.e--animation-grow .elementor-item-active:before,
.e--pointer-background.e--animation-grow .elementor-item.highlighted:before,
.e--pointer-background.e--animation-grow .elementor-item:focus:before,
.e--pointer-background.e--animation-grow .elementor-item:hover:before {
  transform: scale(1);
  opacity: 1;
}
.e--pointer-background.e--animation-shrink
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  transform: scale(1.2);
  transition: 0.3s;
}
.e--pointer-background.e--animation-shrink .elementor-item-active:before,
.e--pointer-background.e--animation-shrink .elementor-item.highlighted:before,
.e--pointer-background.e--animation-shrink .elementor-item:focus:before,
.e--pointer-background.e--animation-shrink .elementor-item:hover:before {
  transition: opacity 0.15s, transform 0.4s;
}
.e--pointer-background.e--animation-sweep-left
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  left: 100%;
}
.e--pointer-background.e--animation-sweep-right
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  right: 100%;
}
.e--pointer-background.e--animation-sweep-up
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  top: 100%;
}
.e--pointer-background.e--animation-sweep-down
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  bottom: 100%;
}
.e--pointer-background.e--animation-shutter-out-vertical
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  bottom: 50%;
  top: 50%;
}
.e--pointer-background.e--animation-shutter-out-horizontal
  .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(
    .highlighted
  ):before {
  right: 50%;
  left: 50%;
}
.e--pointer-background.e--animation-shutter-in-horizontal .elementor-item:after,
.e--pointer-background.e--animation-shutter-in-vertical .elementor-item:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #55595c;
  z-index: -1;
}
.e--pointer-background.e--animation-shutter-in-vertical .elementor-item:before {
  top: 0;
  bottom: 100%;
}
.e--pointer-background.e--animation-shutter-in-vertical .elementor-item:after {
  top: 100%;
  bottom: 0;
}
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item-active:before,
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item.highlighted:before,
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item:focus:before,
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item:hover:before {
  bottom: 50%;
}
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item-active:after,
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item.highlighted:after,
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item:focus:after,
.e--pointer-background.e--animation-shutter-in-vertical
  .elementor-item:hover:after {
  top: 50%;
}
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item:before {
  right: 0;
  left: 100%;
}
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item:after {
  right: 100%;
  left: 0;
}
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item-active:before,
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item.highlighted:before,
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item:focus:before,
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item:hover:before {
  left: 50%;
}
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item-active:after,
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item.highlighted:after,
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item:focus:after,
.e--pointer-background.e--animation-shutter-in-horizontal
  .elementor-item:hover:after {
  right: 50%;
}
.e--pointer-background.e--animation-none,
.e--pointer-background.e--animation-none .elementor-item,
.e--pointer-background.e--animation-none .elementor-item:after,
.e--pointer-background.e--animation-none .elementor-item:before,
.e--pointer-background.e--animation-none .elementor-item:focus,
.e--pointer-background.e--animation-none .elementor-item:hover,
.e--pointer-background.e--animation-none:after,
.e--pointer-background.e--animation-none:before,
.e--pointer-background.e--animation-none:focus,
.e--pointer-background.e--animation-none:hover {
  transition-duration: 0s;
}
.e--pointer-text.e--animation-skew .elementor-item:focus,
.e--pointer-text.e--animation-skew .elementor-item:hover {
  transform: skew(-8deg);
}
.e--pointer-text.e--animation-grow .elementor-item:focus,
.e--pointer-text.e--animation-grow .elementor-item:hover {
  transform: scale(1.2);
}
.e--pointer-text.e--animation-shrink .elementor-item:focus,
.e--pointer-text.e--animation-shrink .elementor-item:hover {
  transform: scale(0.8);
}
.e--pointer-text.e--animation-float .elementor-item:focus,
.e--pointer-text.e--animation-float .elementor-item:hover {
  transform: translateY(-8px);
}
.e--pointer-text.e--animation-sink .elementor-item:focus,
.e--pointer-text.e--animation-sink .elementor-item:hover {
  transform: translateY(8px);
}
.e--pointer-text.e--animation-rotate .elementor-item:focus,
.e--pointer-text.e--animation-rotate .elementor-item:hover {
  transform: rotate(6deg);
}
.e--pointer-text.e--animation-none,
.e--pointer-text.e--animation-none .elementor-item,
.e--pointer-text.e--animation-none .elementor-item:after,
.e--pointer-text.e--animation-none .elementor-item:before,
.e--pointer-text.e--animation-none .elementor-item:focus,
.e--pointer-text.e--animation-none .elementor-item:hover,
.e--pointer-text.e--animation-none:after,
.e--pointer-text.e--animation-none:before,
.e--pointer-text.e--animation-none:focus,
.e--pointer-text.e--animation-none:hover {
  transition-duration: 0s;
}
.elementor-nav-menu--main .elementor-nav-menu a {
  transition: 0.4s;
}
.elementor-nav-menu--main .elementor-nav-menu a,
.elementor-nav-menu--main .elementor-nav-menu a.highlighted,
.elementor-nav-menu--main .elementor-nav-menu a:focus,
.elementor-nav-menu--main .elementor-nav-menu a:hover {
  padding: 13px 20px;
}
.elementor-nav-menu--main .elementor-nav-menu a.current {
  background: #373a3c;
  color: #fff;
}
.elementor-nav-menu--main .elementor-nav-menu a.disabled {
  background: #55595c;
  color: #a1a6a9;
}
.elementor-nav-menu--main .elementor-nav-menu ul {
  position: absolute;
  width: 12em;
  border-width: 0;
  border-style: solid;
  padding: 0;
}
.elementor-nav-menu--main .elementor-nav-menu span.scroll-down,
.elementor-nav-menu--main .elementor-nav-menu span.scroll-up {
  position: absolute;
  display: none;
  visibility: hidden;
  overflow: hidden;
  background: #fff;
  height: 20px;
}
.elementor-nav-menu--main .elementor-nav-menu span.scroll-down-arrow,
.elementor-nav-menu--main .elementor-nav-menu span.scroll-up-arrow {
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  overflow: hidden;
  border: 8px dashed transparent;
  border-bottom: 8px solid #494c4f;
}
.elementor-nav-menu--main .elementor-nav-menu span.scroll-down-arrow {
  top: 6px;
  border-style: solid dashed dashed;
  border-color: #494c4f transparent transparent;
}
.elementor-nav-menu--main
  .elementor-nav-menu--dropdown
  .sub-arrow
  .e-font-icon-svg,
.elementor-nav-menu--main .elementor-nav-menu--dropdown .sub-arrow i {
  transform: rotate(-90deg);
}
.elementor-nav-menu--main
  .elementor-nav-menu--dropdown
  .sub-arrow
  .e-font-icon-svg {
  fill: currentColor;
  height: 1em;
  width: 1em;
}
.elementor-nav-menu--layout-horizontal {
  display: flex;
}
.elementor-nav-menu--layout-horizontal .elementor-nav-menu {
  display: flex;
  flex-wrap: wrap;
}
.elementor-nav-menu--layout-horizontal .elementor-nav-menu a {
  white-space: nowrap;
  flex-grow: 1;
}
.elementor-nav-menu--layout-horizontal .elementor-nav-menu > li {
  display: flex;
}
.elementor-nav-menu--layout-horizontal .elementor-nav-menu > li > .scroll-down,
.elementor-nav-menu--layout-horizontal .elementor-nav-menu > li ul {
  top: 100% !important;
}
.elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li:not(:first-child)
  > a {
  -webkit-margin-start: var(--e-nav-menu-horizontal-menu-item-margin);
  margin-inline-start: var(--e-nav-menu-horizontal-menu-item-margin);
}
.elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li:not(:first-child)
  > .scroll-down,
.elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li:not(:first-child)
  > .scroll-up,
.elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li:not(:first-child)
  > ul {
  left: var(--e-nav-menu-horizontal-menu-item-margin) !important;
}
.elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li:not(:last-child)
  > a {
  -webkit-margin-end: var(--e-nav-menu-horizontal-menu-item-margin);
  margin-inline-end: var(--e-nav-menu-horizontal-menu-item-margin);
}
.elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li:not(:last-child):after {
  content: var(--e-nav-menu-divider-content, none);
  height: var(--e-nav-menu-divider-height, 35%);
  border-left: var(--e-nav-menu-divider-width, 2px)
    var(--e-nav-menu-divider-style, solid) var(--e-nav-menu-divider-color, #000);
  border-bottom-color: var(--e-nav-menu-divider-color, #000);
  border-right-color: var(--e-nav-menu-divider-color, #000);
  border-top-color: var(--e-nav-menu-divider-color, #000);
  align-self: center;
}
.elementor-nav-menu__align-right .elementor-nav-menu {
  margin-left: auto;
  justify-content: flex-end;
}
.elementor-nav-menu__align-right
  .elementor-nav-menu--layout-vertical
  > ul
  > li
  > a {
  justify-content: flex-end;
}
.elementor-nav-menu__align-left .elementor-nav-menu {
  margin-right: auto;
  justify-content: flex-start;
}
.elementor-nav-menu__align-left
  .elementor-nav-menu--layout-vertical
  > ul
  > li
  > a {
  justify-content: flex-start;
}
.elementor-nav-menu__align-center .elementor-nav-menu {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.elementor-nav-menu__align-center
  .elementor-nav-menu--layout-vertical
  > ul
  > li
  > a {
  justify-content: center;
}
.elementor-nav-menu__align-justify
  .elementor-nav-menu--layout-horizontal
  .elementor-nav-menu {
  width: 100%;
}
.elementor-nav-menu__align-justify
  .elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li {
  flex-grow: 1;
}
.elementor-nav-menu__align-justify
  .elementor-nav-menu--layout-horizontal
  .elementor-nav-menu
  > li
  > a {
  justify-content: center;
}
.elementor-widget-nav-menu:not(.elementor-nav-menu--toggle)
  .elementor-menu-toggle {
  display: none;
}
.elementor-widget-nav-menu .elementor-widget-container {
  display: flex;
  flex-direction: column;
}
.elementor-nav-menu {
  position: relative;
  z-index: 2;
}
.elementor-nav-menu:after {
  content: 'Â ';
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  visibility: hidden;
  overflow: hidden;
}
.elementor-nav-menu,
.elementor-nav-menu li,
.elementor-nav-menu ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.elementor-nav-menu ul {
  display: none;
}
.elementor-nav-menu ul ul a,
.elementor-nav-menu ul ul a:active,
.elementor-nav-menu ul ul a:focus,
.elementor-nav-menu ul ul a:hover {
  border-left: 16px solid transparent;
}
.elementor-nav-menu ul ul ul a,
.elementor-nav-menu ul ul ul a:active,
.elementor-nav-menu ul ul ul a:focus,
.elementor-nav-menu ul ul ul a:hover {
  border-left: 24px solid transparent;
}
.elementor-nav-menu ul ul ul ul a,
.elementor-nav-menu ul ul ul ul a:active,
.elementor-nav-menu ul ul ul ul a:focus,
.elementor-nav-menu ul ul ul ul a:hover {
  border-left: 32px solid transparent;
}
.elementor-nav-menu ul ul ul ul ul a,
.elementor-nav-menu ul ul ul ul ul a:active,
.elementor-nav-menu ul ul ul ul ul a:focus,
.elementor-nav-menu ul ul ul ul ul a:hover {
  border-left: 40px solid transparent;
}
.elementor-nav-menu a,
.elementor-nav-menu li {
  position: relative;
}
.elementor-nav-menu li {
  border-width: 0;
}
.elementor-nav-menu a {
  display: flex;
  align-items: center;
}
.elementor-nav-menu a,
.elementor-nav-menu a:focus,
.elementor-nav-menu a:hover {
  padding: 10px 20px;
  line-height: 20px;
}
.elementor-nav-menu a.current {
  background: #373a3c;
  color: #fff;
}
.elementor-nav-menu a.disabled {
  cursor: not-allowed;
  color: #a1a6a9;
}
.elementor-nav-menu .e-plus-icon:before {
  content: '+';
}
.elementor-nav-menu .sub-arrow {
  line-height: 1;
  padding: 10px 0 10px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  display: flex;
  align-items: center;
}
.elementor-nav-menu .sub-arrow i {
  pointer-events: none;
}
.elementor-nav-menu .sub-arrow .fa.fa-chevron-down,
.elementor-nav-menu .sub-arrow .fas.fa-chevron-down {
  font-size: 0.7em;
}
.elementor-nav-menu .sub-arrow .e-font-icon-svg {
  height: 1em;
  width: 1em;
}
.elementor-nav-menu .sub-arrow .e-font-icon-svg.fa-svg-chevron-down {
  height: 0.7em;
  width: 0.7em;
}
.elementor-nav-menu--dropdown .elementor-item.elementor-item-active,
.elementor-nav-menu--dropdown .elementor-item.highlighted,
.elementor-nav-menu--dropdown .elementor-item:focus,
.elementor-nav-menu--dropdown .elementor-item:hover,
.elementor-sub-item.elementor-item-active,
.elementor-sub-item.highlighted,
.elementor-sub-item:focus,
.elementor-sub-item:hover {
  background-color: #55595c;
  color: #fff;
}
.elementor-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--nav-menu-icon-size, 22px);
  padding: 0.25em;
  cursor: pointer;
  border: 0 solid;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #494c4f;
}
.elementor-menu-toggle.elementor-active .elementor-menu-toggle__icon--open,
.elementor-menu-toggle:not(.elementor-active)
  .elementor-menu-toggle__icon--close {
  display: none;
}
.elementor-menu-toggle .e-font-icon-svg {
  fill: #494c4f;
  height: 1em;
  width: 1em;
}
.elementor-menu-toggle svg {
  width: 1em;
  height: auto;
  fill: var(--nav-menu-icon-color, currentColor);
}
span.elementor-menu-toggle__icon--close,
span.elementor-menu-toggle__icon--open {
  line-height: 1;
}
.elementor-nav-menu--dropdown {
  background-color: #fff;
  font-size: 13px;
}
.elementor-nav-menu--dropdown-none .elementor-menu-toggle,
.elementor-nav-menu--dropdown-none .elementor-nav-menu--dropdown {
  display: none;
}
.elementor-nav-menu--dropdown.elementor-nav-menu__container {
  margin-top: 10px;
  transition: max-height 0.3s, transform 0.3s;
  transform-origin: top;
  overflow-y: auto;
  overflow-x: hidden;
}
.elementor-nav-menu--dropdown.elementor-nav-menu__container
  .elementor-sub-item {
  font-size: 0.85em;
}
.elementor-nav-menu--dropdown a {
  color: #494c4f;
}
.elementor-nav-menu--dropdown a.current {
  background: #373a3c;
  color: #fff;
}
.elementor-nav-menu--dropdown a.disabled {
  color: #b3b3b3;
}
ul.elementor-nav-menu--dropdown a,
ul.elementor-nav-menu--dropdown a:focus,
ul.elementor-nav-menu--dropdown a:hover {
  text-shadow: none;
  border-left: 8px solid transparent;
}
.elementor-nav-menu__text-align-center
  .elementor-nav-menu--dropdown
  .elementor-nav-menu
  a {
  justify-content: center;
}
.elementor-nav-menu--toggle {
  --menu-height: 100vh;
}
.elementor-nav-menu--toggle
  .elementor-menu-toggle:not(.elementor-active)
  + .elementor-nav-menu__container {
  transform: scaleY(0);
  max-height: 0;
  overflow: hidden;
}
.elementor-nav-menu--toggle
  .elementor-menu-toggle.elementor-active
  + .elementor-nav-menu__container {
  transform: scaleY(1);
  max-height: var(--menu-height);
  animation: hide-scroll 0.3s backwards;
}
.elementor-nav-menu--stretch
  .elementor-nav-menu__container.elementor-nav-menu--dropdown {
  position: absolute;
  z-index: 9997;
}
@media (max-width: 767px) {
  .elementor-nav-menu--dropdown-mobile .elementor-nav-menu--main {
    display: none;
  }
}
@media (min-width: 768px) {
  .elementor-nav-menu--dropdown-mobile .elementor-menu-toggle,
  .elementor-nav-menu--dropdown-mobile .elementor-nav-menu--dropdown {
    display: none;
  }
  .elementor-nav-menu--dropdown-mobile
    nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
    overflow-y: hidden;
  }
}
@media (max-width: '-1px') {
  .elementor-nav-menu--dropdown-mobile_extra .elementor-nav-menu--main {
    display: none;
  }
}
@media (min-width: '-1px') {
  .elementor-nav-menu--dropdown-mobile_extra .elementor-menu-toggle,
  .elementor-nav-menu--dropdown-mobile_extra .elementor-nav-menu--dropdown {
    display: none;
  }
  .elementor-nav-menu--dropdown-mobile_extra
    nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
    overflow-y: hidden;
  }
}
@media (min-width: 1025px) {
  .elementor-nav-menu--dropdown-tablet .elementor-menu-toggle,
  .elementor-nav-menu--dropdown-tablet .elementor-nav-menu--dropdown {
    display: none;
  }
  .elementor-nav-menu--dropdown-tablet
    nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
    overflow-y: hidden;
  }
}
@media (max-width: 1024px) {
  .elementor-nav-menu--dropdown-tablet .elementor-nav-menu--main {
    display: none;
  }
}
@media (max-width: '-1px') {
  .elementor-nav-menu--dropdown-tablet_extra .elementor-menu-toggle,
  .elementor-nav-menu--dropdown-tablet_extra .elementor-nav-menu--dropdown {
    display: none;
  }
  .elementor-nav-menu--dropdown-tablet_extra
    nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
    overflow-y: hidden;
  }
}
@media (min-width: '-1px') {
  .elementor-nav-menu--dropdown-tablet_extra .elementor-menu-toggle,
  .elementor-nav-menu--dropdown-tablet_extra .elementor-nav-menu--dropdown {
    display: none;
  }
}
@keyframes hide-scroll {
  0%,
  to {
    overflow: hidden;
  }
}
