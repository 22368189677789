@use '_theme.scss';

#page-approve-publish-post-failed {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinning-icon {
  animation: spin 2s linear infinite;
}
