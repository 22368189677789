@use '_theme.scss';

#page-account {
  background-color: #eeecec;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  & > .account-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    & > .account-container-row {
      flex: 1;
      display: flex;
      & > .account-content-col {
        display: flex;
        flex: 1;
        flex-direction: column;
        & > .account-content-card {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
      }
    }
    .account-subsection-full-width {
      grid-column: 1 / span 2;
    }
    .account-subsection-header {
      font-size: 1.4em;
      text-align: center;
      border-bottom: 2px solid #cfd9ea;
      margin: auto;
      padding-bottom: 4px;
      margin-top: 20px;
    }
    .authentication-setup-chooser-wrapper {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      column-gap: 10px;
      .authentication-setup-automatic-wrapper {
        display: flex;
        justify-content: center;
        button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 900;
          padding: 20px;
          gap: 10px;
          width: 200px;
        }
      }
      .authentication-setup-separator-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .authentication-setup-separator {
          min-height: 40px;
          width: 2px;
          background: #cfd9ea;
        }
      }
    }
  }
  .list-group {
    .list-group-item {
      a {
        color: var(--bs-list-group-color);
        text-decoration: none;
        &:hover {
          text-shadow: 0 0 0.65px var(--bs-list-group-color),
            0 0 0.65px var(--bs-list-group-color);
        }
      }
      &.active {
        a {
          color: #018efe;
          &:hover {
            text-shadow: none;
          }
        }
      }
      svg.notification {
        position: relative;
        top: -0.75em;
      }
    }
  }
  .list-group:not(.list-group-horizontal) {
    padding-top: 56px;
    .list-group-item {
      background: none;
      border: none;
      border-radius: 0;
      cursor: pointer;
      border-left: 2px solid #cfd9ea;
      text-indent: 1px;
      &:hover {
        border-left: 2px solid #a1b1ce;
      }
      &.active {
        border-left: 3px solid #018efe;
        font-weight: 900;
        color: #018efe;
        text-indent: 0px;
      }
    }
  }
  .list-group.list-group-horizontal {
    .list-group-item {
      background: none;
      border: none;
      border-radius: 0;
      cursor: pointer;
      border-bottom: 2px solid #cfd9ea;
      height: unset !important;
      border-right: none !important;
      vertical-align: unset;
      margin-top: unset;
      &:hover {
        border-bottom: 2px solid #a1b1ce;
      }
      &.active {
        border-bottom: 3px solid #018efe;
        font-weight: 900;
        color: #018efe;
      }
    }
  }
  .account-profile {
    display: grid;
    grid-template-columns: 180px 1fr;
    justify-items: start;
    align-items: start;
    row-gap: 20px;
  }

  .account-form-wrapper {
    width: 100%;
    &.calendar > .form-check {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding: 0;
      flex-wrap: wrap;
    }
    &.next-scheduled-post {
      font-size: 0.8em;
      font-weight: 900;
      color: #018efe;
    }
  }
  .account-form-label {
    margin-top: 7px;
  }
  .post-content {
    .accordion-item {
      &.published,
      &.drafted,
      &.scheduled {
        .accordion-header-spacer {
          display: block !important;
          flex: 1;
        }
        .accordion-header-date {
          display: block !important;
          margin-right: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          svg {
            margin-right: 4px;
          }
        }
      }
      &.published {
        .accordion-header-date {
          color: #018efe;
        }
      }
      .accordion-header {
        .accordion-header-spacer,
        .accordion-header-date {
          display: none;
        }
        button.accordion-button {
          &:not(.collapsed) {
            background: #c4f3eb;
            color: #000;
          }
          &:focus {
            box-shadow: none !important;
            border-color: none !important;
          }
        }
      }
      .accordion-body {
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;
        .post-details {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 120px 1fr;
          .labels {
            font-weight: bold;
          }
        }
      }
      img.post-screenshot {
        width: 100%;
        max-width: 500px;
        border: 1px solid black;
      }
    }
  }
  .strategy-content {
    .strategy-months-accordion-item {
      margin: 20px 0;
      .accordion-button:not(.collapsed) {
        background-color: #018efe;
        color: #ffffff;
      }
      table {
        thead {
          tr {
            th {
              &:last-of-type {
                padding-right: 15px;
                svg {
                  right: 0px;
                }
              }
              &.no-sort {
                cursor: default;
              }
              cursor: pointer;
              position: relative;
              & > div {
                display: inline;
              }
              svg {
                display: none;
                position: absolute;
                right: -5px;
                top: 12px;
              }
              &:not(.default-sort-down):hover {
                svg {
                  &.sort-down {
                    display: inline;
                  }
                }
              }
              &.sorted-up {
                svg {
                  &.sort-up {
                    display: inline;
                  }
                }
                &:hover {
                  svg {
                    &.sort-down {
                      display: inline;
                      opacity: 0.25;
                    }
                  }
                }
              }
              &.sorted-down {
                svg {
                  &.sort-down {
                    display: inline;
                  }
                }
                &:hover {
                  svg {
                    &.sort-up {
                      display: inline;
                      opacity: 0.25;
                    }
                  }
                }
              }
              &.default-sort-down {
                &:not(.sorted-down):not(.sorted-up) {
                  &:hover {
                    svg {
                      &.sort-up {
                        display: inline;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tbody {
          tr {
            td:last-of-type {
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
}

ol.manual-user-credentials-instructions-list {
  & > li {
    margin-bottom: 20px;
    li {
      margin-bottom: 10px;
    }
  }
}
