#page-sample {
  background: #eeecec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  & > .sample-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.invalid-feedback {
  display: inherit !important;
}
