@use '_theme.scss';

ul.main-menu {
  li {
    a.account-button {
      &.logged-in {
        &:hover {
          border-radius: 0px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
    &:hover {
      &.btn-design.menu-item {
        a.account-button.logged-in {
          background: #000 !important;
          background: #000 !important;
          color: #fff !important;
          fill: #000 !important;
          border-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
      ul.account-dropdown-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #000;
        color: white;
        backdrop-filter: blur(12px) brightness(40%);
      }
    }
    ul.account-dropdown-menu {
      display: none;
      width: calc(100% - 15px);
      li {
        width: calc(100% - 30px);
        height: 56px;
        justify-content: stretch;
        align-items: center;
        border-top: 1px solid #aeaeae;
        &:first-child {
          border-top: none;
        }
        a {
          padding: 0;
          margin: 0;
          color: #fff !important;
          margin-inline-end: 0 !important;
          &:hover {
            padding: 0;
            margin: 0;
            font-weight: 900;
          }
        }
      }
    }
  }
}

nav.navbar {
  .nav-account-button-wrapper {
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: stretch;
    align-items: center;
    position: relative;
    a {
      width: 100%;
      button {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
      }
      &:first-of-type {
        button {
          border-radius: 100px;
        }
      }
      &:last-of-type {
        button {
          position: absolute;
          top: 48px;
          border-radius: 0 0 12px 12px;
        }
      }
      &:not(:first-of-type) {
        display: none;
      }
    }
    &:hover {
      a {
        &:not(:first-of-type) {
          display: block;
        }
        &:first-of-type {
          button {
            border-radius: 12px 12px 0 0;
          }
        }
      }
    }
  }
}
