.elementor-widget-heading .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-image .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-text-editor {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: var(--e-global-color-primary);
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap,
.elementor-widget-text-editor.elementor-drop-cap-view-default
  .elementor-drop-cap {
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}
.elementor-widget-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-divider {
  --divider-color: var(--e-global-color-secondary);
}
.elementor-widget-divider .elementor-divider__text {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-divider.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-secondary);
}
.elementor-widget-divider.elementor-view-framed .elementor-icon,
.elementor-widget-divider.elementor-view-default .elementor-icon {
  color: var(--e-global-color-secondary);
  border-color: var(--e-global-color-secondary);
}
.elementor-widget-divider.elementor-view-framed .elementor-icon,
.elementor-widget-divider.elementor-view-default .elementor-icon svg {
  fill: var(--e-global-color-secondary);
}
.elementor-widget-image-box .elementor-image-box-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-image-box .elementor-image-box-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-icon.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-primary);
}
.elementor-widget-icon.elementor-view-framed .elementor-icon,
.elementor-widget-icon.elementor-view-default .elementor-icon {
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}
.elementor-widget-icon.elementor-view-framed .elementor-icon,
.elementor-widget-icon.elementor-view-default .elementor-icon svg {
  fill: var(--e-global-color-primary);
}
.elementor-widget-icon-box.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-primary);
}
.elementor-widget-icon-box.elementor-view-framed .elementor-icon,
.elementor-widget-icon-box.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}
.elementor-widget-icon-box .elementor-icon-box-title {
  color: var(--e-global-color-primary);
}
.elementor-widget-icon-box .elementor-icon-box-title,
.elementor-widget-icon-box .elementor-icon-box-title a {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-icon-box .elementor-icon-box-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-star-rating .elementor-star-rating__title {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-image-gallery .gallery-item .gallery-caption {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-icon-list .elementor-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text);
}
.elementor-widget-icon-list .elementor-icon-list-icon i {
  color: var(--e-global-color-primary);
}
.elementor-widget-icon-list .elementor-icon-list-icon svg {
  fill: var(--e-global-color-primary);
}
.elementor-widget-icon-list .elementor-icon-list-text {
  color: var(--e-global-color-secondary);
}
.elementor-widget-icon-list
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-widget-icon-list .elementor-icon-list-item > a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-counter .elementor-counter-number-wrapper {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-counter .elementor-counter-title {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-progress .elementor-progress-wrapper .elementor-progress-bar {
  background-color: var(--e-global-color-primary);
}
.elementor-widget-progress .elementor-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-testimonial .elementor-testimonial-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-testimonial .elementor-testimonial-name {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-testimonial .elementor-testimonial-job {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-tabs .elementor-tab-title,
.elementor-widget-tabs .elementor-tab-title a {
  color: var(--e-global-color-primary);
}
.elementor-widget-tabs .elementor-tab-title.elementor-active,
.elementor-widget-tabs .elementor-tab-title.elementor-active a {
  color: var(--e-global-color-accent);
}
.elementor-widget-tabs .elementor-tab-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-tabs .elementor-tab-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-accordion .elementor-accordion-icon,
.elementor-widget-accordion .elementor-accordion-title {
  color: var(--e-global-color-primary);
}
.elementor-widget-accordion .elementor-accordion-icon svg {
  fill: var(--e-global-color-primary);
}
.elementor-widget-accordion .elementor-active .elementor-accordion-icon,
.elementor-widget-accordion .elementor-active .elementor-accordion-title {
  color: var(--e-global-color-accent);
}
.elementor-widget-accordion .elementor-active .elementor-accordion-icon svg {
  fill: var(--e-global-color-accent);
}
.elementor-widget-accordion .elementor-accordion-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-accordion .elementor-tab-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-toggle .elementor-toggle-title,
.elementor-widget-toggle .elementor-toggle-icon {
  color: var(--e-global-color-primary);
}
.elementor-widget-toggle .elementor-toggle-icon svg {
  fill: var(--e-global-color-primary);
}
.elementor-widget-toggle .elementor-tab-title.elementor-active a,
.elementor-widget-toggle
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: var(--e-global-color-accent);
}
.elementor-widget-toggle .elementor-toggle-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-toggle .elementor-tab-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-alert .elementor-alert-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-alert .elementor-alert-description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-text-path {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-theme-site-logo .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-theme-site-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-theme-page-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-theme-post-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-theme-post-excerpt .elementor-widget-container {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-theme-post-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-theme-post-featured-image .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-theme-archive-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-archive-posts .elementor-post__title,
.elementor-widget-archive-posts .elementor-post__title a {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-archive-posts .elementor-post__meta-data {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-archive-posts .elementor-post__excerpt p {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-archive-posts .elementor-post__read-more {
  color: var(--e-global-color-accent);
}
.elementor-widget-archive-posts a.elementor-post__read-more {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-archive-posts .elementor-post__card .elementor-post__badge {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-archive-posts .elementor-pagination {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-archive-posts .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-archive-posts .e-load-more-message {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-archive-posts .elementor-posts-nothing-found {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-loop-grid .elementor-pagination {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-loop-grid .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-loop-grid .e-load-more-message {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-posts .elementor-post__title,
.elementor-widget-posts .elementor-post__title a {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-posts .elementor-post__meta-data {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-posts .elementor-post__excerpt p {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-posts .elementor-post__read-more {
  color: var(--e-global-color-accent);
}
.elementor-widget-posts a.elementor-post__read-more {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-posts .elementor-post__card .elementor-post__badge {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-posts .elementor-pagination {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-posts .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-posts .e-load-more-message {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-portfolio a .elementor-portfolio-item__overlay {
  background-color: var(--e-global-color-accent);
}
.elementor-widget-portfolio .elementor-portfolio-item__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-portfolio .elementor-portfolio__filter {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-portfolio .elementor-portfolio__filter.elementor-active {
  color: var(--e-global-color-primary);
}
.elementor-widget-gallery .elementor-gallery-item__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-gallery .elementor-gallery-item__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-gallery {
  --galleries-title-color-normal: var(--e-global-color-primary);
  --galleries-title-color-hover: var(--e-global-color-secondary);
  --galleries-pointer-bg-color-hover: var(--e-global-color-accent);
  --gallery-title-color-active: var(--e-global-color-secondary);
  --galleries-pointer-bg-color-active: var(--e-global-color-accent);
}
.elementor-widget-gallery .elementor-gallery-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-form .elementor-field-group > label,
.elementor-widget-form .elementor-field-subgroup label {
  color: var(--e-global-color-text);
}
.elementor-widget-form .elementor-field-group > label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-form .elementor-field-type-html {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-form .elementor-field-group .elementor-field {
  color: var(--e-global-color-text);
}
.elementor-widget-form .elementor-field-group .elementor-field,
.elementor-widget-form .elementor-field-subgroup label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-form .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-form .e-form__buttons__wrapper__button-next {
  background-color: var(--e-global-color-accent);
}
.elementor-widget-form .elementor-button[type='submit'] {
  background-color: var(--e-global-color-accent);
}
.elementor-widget-form .e-form__buttons__wrapper__button-previous {
  background-color: var(--e-global-color-accent);
}
.elementor-widget-form .elementor-message {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-form .e-form__indicators__indicator,
.elementor-widget-form .e-form__indicators__indicator__label {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-form {
  --e-form-steps-indicator-inactive-primary-color: var(--e-global-color-text);
  --e-form-steps-indicator-active-primary-color: var(--e-global-color-accent);
  --e-form-steps-indicator-completed-primary-color: var(
    --e-global-color-accent
  );
  --e-form-steps-indicator-progress-color: var(--e-global-color-accent);
  --e-form-steps-indicator-progress-background-color: var(
    --e-global-color-text
  );
  --e-form-steps-indicator-progress-meter-color: var(--e-global-color-text);
}
.elementor-widget-form .e-form__indicators__indicator__progress__meter {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-login .elementor-field-group > a {
  color: var(--e-global-color-text);
}
.elementor-widget-login .elementor-field-group > a:hover {
  color: var(--e-global-color-accent);
}
.elementor-widget-login .elementor-form-fields-wrapper label {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-login .elementor-field-group .elementor-field {
  color: var(--e-global-color-text);
}
.elementor-widget-login .elementor-field-group .elementor-field,
.elementor-widget-login .elementor-field-subgroup label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-login .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-login
  .elementor-widget-container
  .elementor-login__logged-in-message {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-slides .elementor-slide-heading {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-slides .elementor-slide-description {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-slides .elementor-slide-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-nav-menu .elementor-nav-menu .elementor-item {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item {
  color: var(--e-global-color-text);
  fill: var(--e-global-color-text);
}
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item:hover,
.elementor-widget-nav-menu
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active,
.elementor-widget-nav-menu
  .elementor-nav-menu--main
  .elementor-item.highlighted,
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item:focus {
  color: var(--e-global-color-accent);
  fill: var(--e-global-color-accent);
}
.elementor-widget-nav-menu
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:before,
.elementor-widget-nav-menu
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:after {
  background-color: var(--e-global-color-accent);
}
.elementor-widget-nav-menu .e--pointer-framed .elementor-item:before,
.elementor-widget-nav-menu .e--pointer-framed .elementor-item:after {
  border-color: var(--e-global-color-accent);
}
.elementor-widget-nav-menu {
  --e-nav-menu-divider-color: var(--e-global-color-text);
}
.elementor-widget-nav-menu .elementor-nav-menu--dropdown .elementor-item,
.elementor-widget-nav-menu .elementor-nav-menu--dropdown .elementor-sub-item {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-animated-headline .elementor-headline-dynamic-wrapper path {
  stroke: var(--e-global-color-accent);
}
.elementor-widget-animated-headline .elementor-headline-plain-text {
  color: var(--e-global-color-secondary);
}
.elementor-widget-animated-headline .elementor-headline {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-animated-headline {
  --dynamic-text-color: var(--e-global-color-secondary);
}
.elementor-widget-animated-headline .elementor-headline-dynamic-text {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-hotspot .widget-image-caption {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-hotspot {
  --hotspot-color: var(--e-global-color-primary);
  --hotspot-box-color: var(--e-global-color-secondary);
  --tooltip-color: var(--e-global-color-secondary);
}
.elementor-widget-hotspot .e-hotspot__label {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-hotspot .e-hotspot__tooltip {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-price-list .elementor-price-list-header {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-price-list .elementor-price-list-price {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-price-list .elementor-price-list-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-price-list .elementor-price-list-separator {
  border-bottom-color: var(--e-global-color-secondary);
}
.elementor-widget-price-table {
  --e-price-table-header-background-color: var(--e-global-color-secondary);
}
.elementor-widget-price-table .elementor-price-table__heading {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-price-table .elementor-price-table__subheading {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-price-table
  .elementor-price-table
  .elementor-price-table__price {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-price-table .elementor-price-table__original-price {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-price-table .elementor-price-table__period {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-price-table .elementor-price-table__features-list {
  --e-price-table-features-list-color: var(--e-global-color-text);
}
.elementor-widget-price-table .elementor-price-table__features-list li {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-price-table .elementor-price-table__features-list li:before {
  border-top-color: var(--e-global-color-text);
}
.elementor-widget-price-table .elementor-price-table__button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-price-table .elementor-price-table__additional_info {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-price-table .elementor-price-table__ribbon-inner {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-flip-box
  .elementor-flip-box__front
  .elementor-flip-box__layer__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-flip-box
  .elementor-flip-box__front
  .elementor-flip-box__layer__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-flip-box
  .elementor-flip-box__back
  .elementor-flip-box__layer__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-flip-box
  .elementor-flip-box__back
  .elementor-flip-box__layer__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-flip-box .elementor-flip-box__button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-call-to-action .elementor-cta__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-call-to-action .elementor-cta__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-call-to-action .elementor-cta__button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-call-to-action .elementor-ribbon-inner {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-media-carousel .elementor-carousel-image-overlay {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-testimonial-carousel .elementor-testimonial__text {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-testimonial-carousel .elementor-testimonial__name {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-testimonial-carousel .elementor-testimonial__title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-reviews .elementor-testimonial__header,
.elementor-widget-reviews .elementor-testimonial__name {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-reviews .elementor-testimonial__text {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-table-of-contents {
  --header-color: var(--e-global-color-secondary);
  --item-text-color: var(--e-global-color-text);
  --item-text-hover-color: var(--e-global-color-accent);
  --marker-color: var(--e-global-color-text);
}
.elementor-widget-table-of-contents .elementor-toc__header,
.elementor-widget-table-of-contents .elementor-toc__header-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-table-of-contents .elementor-toc__list-item {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-countdown .elementor-countdown-item {
  background-color: var(--e-global-color-primary);
}
.elementor-widget-countdown .elementor-countdown-digits {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-countdown .elementor-countdown-label {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-countdown .elementor-countdown-expire--message {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-search-form
  input[type='search'].elementor-search-form__input {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-search-form .elementor-search-form__input,
.elementor-widget-search-form .elementor-search-form__icon,
.elementor-widget-search-form .elementor-lightbox .dialog-lightbox-close-button,
.elementor-widget-search-form
  .elementor-lightbox
  .dialog-lightbox-close-button:hover,
.elementor-widget-search-form.elementor-search-form--skin-full_screen
  input[type='search'].elementor-search-form__input {
  color: var(--e-global-color-text);
  fill: var(--e-global-color-text);
}
.elementor-widget-search-form .elementor-search-form__submit {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
  background-color: var(--e-global-color-secondary);
}
.elementor-widget-author-box .elementor-author-box__name {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-author-box .elementor-author-box__bio {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-author-box .elementor-author-box__button {
  color: var(--e-global-color-secondary);
  border-color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-author-box .elementor-author-box__button:hover {
  border-color: var(--e-global-color-secondary);
  color: var(--e-global-color-secondary);
}
.elementor-widget-post-navigation span.post-navigation__prev--label {
  color: var(--e-global-color-text);
}
.elementor-widget-post-navigation span.post-navigation__next--label {
  color: var(--e-global-color-text);
}
.elementor-widget-post-navigation span.post-navigation__prev--label,
.elementor-widget-post-navigation span.post-navigation__next--label {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-post-navigation span.post-navigation__prev--title,
.elementor-widget-post-navigation span.post-navigation__next--title {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}
.elementor-widget-post-info .elementor-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text);
}
.elementor-widget-post-info .elementor-icon-list-icon i {
  color: var(--e-global-color-primary);
}
.elementor-widget-post-info .elementor-icon-list-icon svg {
  fill: var(--e-global-color-primary);
}
.elementor-widget-post-info .elementor-icon-list-text,
.elementor-widget-post-info .elementor-icon-list-text a {
  color: var(--e-global-color-secondary);
}
.elementor-widget-post-info .elementor-icon-list-item {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-sitemap .elementor-sitemap-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}
.elementor-widget-sitemap .elementor-sitemap-item,
.elementor-widget-sitemap span.elementor-sitemap-list,
.elementor-widget-sitemap .elementor-sitemap-item a {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-sitemap .elementor-sitemap-item {
  color: var(--e-global-color-text);
}
.elementor-widget-blockquote .elementor-blockquote__content {
  color: var(--e-global-color-text);
}
.elementor-widget-blockquote .elementor-blockquote__author {
  color: var(--e-global-color-secondary);
}
.elementor-widget-lottie {
  --caption-color: var(--e-global-color-text);
}
.elementor-widget-lottie .e-lottie__caption {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-video-playlist .e-tabs-header .e-tabs-title {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist .e-tabs-header .e-tabs-videos-count {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side i {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side svg {
  fill: var(--e-global-color-text);
}
.elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-video-playlist .e-tab-title .e-tab-title-text a {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist .e-tab-title .e-tab-duration {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist
  .e-tabs-items-wrapper
  .e-tab-title:where(.e-active, :hover)
  .e-tab-title-text {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-video-playlist
  .e-tabs-items-wrapper
  .e-tab-title:where(.e-active, :hover)
  .e-tab-title-text
  a {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist
  .e-tabs-items-wrapper
  .e-tab-title:where(.e-active, :hover)
  .e-tab-duration {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist .e-tabs-items-wrapper .e-section-title {
  color: var(--e-global-color-text);
}
.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-wrapper
  .e-inner-tab-title
  a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-content-wrapper
  .e-inner-tab-content
  .e-inner-tab-text {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-content-wrapper
  .e-inner-tab-content
  button {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}
.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-content-wrapper
  .e-inner-tab-content
  button:hover {
  color: var(--e-global-color-text);
}
.elementor-widget-paypal-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-paypal-button .elementor-message {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-stripe-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}
.elementor-widget-stripe-button .elementor-message {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
.elementor-widget-progress-tracker .current-progress-percentage {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-size: var(--e-global-typography-text-font-size);
  font-weight: var(--e-global-typography-text-font-weight);
  line-height: var(--e-global-typography-text-line-height);
}
@media (max-width: 1024px) {
  .elementor-widget-image .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-text-editor {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-image-box .elementor-image-box-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-icon-box .elementor-icon-box-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-star-rating .elementor-star-rating__title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-icon-list
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-widget-icon-list .elementor-icon-list-item > a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-progress .elementor-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-testimonial .elementor-testimonial-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-tabs .elementor-tab-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-accordion .elementor-tab-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-toggle .elementor-tab-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-alert .elementor-alert-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-text-path {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-site-logo .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-post-excerpt .elementor-widget-container {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-post-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-post-featured-image .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-archive-posts .elementor-post__excerpt p {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-archive-posts .elementor-posts-nothing-found {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-posts .elementor-post__excerpt p {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-gallery .elementor-gallery-item__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-field-group > label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-field-type-html {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-field-group .elementor-field,
  .elementor-widget-form .elementor-field-subgroup label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-login .elementor-form-fields-wrapper label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-login .elementor-field-group .elementor-field,
  .elementor-widget-login .elementor-field-subgroup label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-login
    .elementor-widget-container
    .elementor-login__logged-in-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-hotspot .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-price-list .elementor-price-list-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-price-table .elementor-price-table__features-list li {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-price-table .elementor-price-table__additional_info {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-flip-box
    .elementor-flip-box__front
    .elementor-flip-box__layer__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-flip-box
    .elementor-flip-box__back
    .elementor-flip-box__layer__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-call-to-action .elementor-cta__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-testimonial-carousel .elementor-testimonial__text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-reviews .elementor-testimonial__text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-table-of-contents .elementor-toc__list-item {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-countdown .elementor-countdown-digits {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-countdown .elementor-countdown-expire--message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-search-form
    input[type='search'].elementor-search-form__input {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-search-form .elementor-search-form__submit {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-author-box .elementor-author-box__bio {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-post-info .elementor-icon-list-item {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-sitemap .elementor-sitemap-item,
  .elementor-widget-sitemap span.elementor-sitemap-list,
  .elementor-widget-sitemap .elementor-sitemap-item a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-lottie .e-lottie__caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist
    .e-tabs-items-wrapper
    .e-tab-title:where(.e-active, :hover)
    .e-tab-title-text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist
    .e-tabs-inner-tabs
    .e-inner-tabs-wrapper
    .e-inner-tab-title
    a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist
    .e-tabs-inner-tabs
    .e-inner-tabs-content-wrapper
    .e-inner-tab-content
    .e-inner-tab-text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-paypal-button .elementor-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-stripe-button .elementor-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-progress-tracker .current-progress-percentage {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
}
@media (max-width: 767px) {
  .elementor-widget-image .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-text-editor {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-image-box .elementor-image-box-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-icon-box .elementor-icon-box-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-star-rating .elementor-star-rating__title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-icon-list
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-widget-icon-list .elementor-icon-list-item > a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-progress .elementor-title {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-testimonial .elementor-testimonial-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-tabs .elementor-tab-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-accordion .elementor-tab-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-toggle .elementor-tab-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-alert .elementor-alert-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-text-path {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-site-logo .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-post-excerpt .elementor-widget-container {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-post-content {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-theme-post-featured-image .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-archive-posts .elementor-post__excerpt p {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-archive-posts .elementor-posts-nothing-found {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-posts .elementor-post__excerpt p {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-gallery .elementor-gallery-item__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-field-group > label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-field-type-html {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-field-group .elementor-field,
  .elementor-widget-form .elementor-field-subgroup label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-form .elementor-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-login .elementor-form-fields-wrapper label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-login .elementor-field-group .elementor-field,
  .elementor-widget-login .elementor-field-subgroup label {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-login
    .elementor-widget-container
    .elementor-login__logged-in-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-hotspot .widget-image-caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-price-list .elementor-price-list-description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-price-table .elementor-price-table__features-list li {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-price-table .elementor-price-table__additional_info {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-flip-box
    .elementor-flip-box__front
    .elementor-flip-box__layer__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-flip-box
    .elementor-flip-box__back
    .elementor-flip-box__layer__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-call-to-action .elementor-cta__description {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-testimonial-carousel .elementor-testimonial__text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-reviews .elementor-testimonial__text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-table-of-contents .elementor-toc__list-item {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-countdown .elementor-countdown-digits {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-countdown .elementor-countdown-expire--message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-search-form
    input[type='search'].elementor-search-form__input {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-search-form .elementor-search-form__submit {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-author-box .elementor-author-box__bio {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-post-info .elementor-icon-list-item {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-sitemap .elementor-sitemap-item,
  .elementor-widget-sitemap span.elementor-sitemap-list,
  .elementor-widget-sitemap .elementor-sitemap-item a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-lottie .e-lottie__caption {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist
    .e-tabs-items-wrapper
    .e-tab-title:where(.e-active, :hover)
    .e-tab-title-text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist
    .e-tabs-inner-tabs
    .e-inner-tabs-wrapper
    .e-inner-tab-title
    a {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-video-playlist
    .e-tabs-inner-tabs
    .e-inner-tabs-content-wrapper
    .e-inner-tab-content
    .e-inner-tab-text {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-paypal-button .elementor-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-stripe-button .elementor-message {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
  .elementor-widget-progress-tracker .current-progress-percentage {
    font-size: var(--e-global-typography-text-font-size);
    line-height: var(--e-global-typography-text-line-height);
  }
}
.elementor-7
  .elementor-element.elementor-element-7c29f3b:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-7
  .elementor-element.elementor-element-7c29f3b
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #00002d;
}
.elementor-7
  .elementor-element.elementor-element-7c29f3b
  > .elementor-background-overlay {
  display: none;
  background-image: url('../public/img/eclipse.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 567px auto;
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-7c29f3b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-914c960
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 20px;
}
.elementor-7
  .elementor-element.elementor-element-914c960
  > .elementor-element-populated {
  padding: 130px 10px 210px 170px;
}
.elementor-7
  .elementor-element.elementor-element-e7802e9
  .elementor-heading-title {
  color: #ffffff;
  font-family: 'Poppins', Sans-serif;
  font-size: 64px;
  font-weight: 600;
  line-height: 74px;
}
.elementor-7 .elementor-element.elementor-element-31d8279 {
  color: #ffffff;
  font-family: 'Poppins', Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.elementor-7 .elementor-element.elementor-element-3d429ba .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
  fill: #020202;
  color: #020202;
  background-color: #018efe;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #018efe;
  border-radius: 100px 100px 100px 100px;
  padding: 18px 38px 18px 38px;
}
.elementor-7
  .elementor-element.elementor-element-3d429ba
  .elementor-button:hover,
.elementor-7
  .elementor-element.elementor-element-3d429ba
  .elementor-button:focus {
  color: #ffffff;
  background-color: #ffffff00;
  border-color: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-3d429ba
  .elementor-button:hover
  svg,
.elementor-7
  .elementor-element.elementor-element-3d429ba
  .elementor-button:focus
  svg {
  fill: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-3d429ba
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}
.elementor-7 .elementor-element.elementor-element-3d429ba {
  width: auto;
  max-width: auto;
}
.elementor-7 .elementor-element.elementor-element-0aa6bab .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ffffff;
  border-radius: 100px 100px 100px 100px;
  padding: 18px 32px 18px 32px;
}
.elementor-7
  .elementor-element.elementor-element-0aa6bab
  .elementor-button:hover,
.elementor-7
  .elementor-element.elementor-element-0aa6bab
  .elementor-button:focus {
  color: #020202;
  background-color: #018efe;
  border-color: #018efe;
}
.elementor-7
  .elementor-element.elementor-element-0aa6bab
  .elementor-button:hover
  svg,
.elementor-7
  .elementor-element.elementor-element-0aa6bab
  .elementor-button:focus
  svg {
  fill: #020202;
}
.elementor-7
  .elementor-element.elementor-element-0aa6bab
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
  padding: 0px 0px 0px 20px;
}
.elementor-7 .elementor-element.elementor-element-0aa6bab {
  width: auto;
  max-width: auto;
}
.elementor-7
  .elementor-element.elementor-element-9618e35:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-9618e35
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url('../public/paige-robot-at-work.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.elementor-7
  .elementor-element.elementor-element-9618e35
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 10px 0px 0px 10px;
}
.elementor-7
  .elementor-element.elementor-element-9618e35
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-efd8687 {
  --spacer-size: 350px;
}
.elementor-7
  .elementor-element.elementor-element-be33fab
  > .elementor-container {
  max-width: 1200px;
}
.elementor-7 .elementor-element.elementor-element-be33fab {
  padding: 105px 0px 110px 0px;
}
.elementor-7 .elementor-element.elementor-element-1930e64 {
  text-align: left;
}
.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-e5a8d79.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-7
  .elementor-element.elementor-element-e5a8d79.elementor-column.elementor-element[data-element_type='column']
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-7
  .elementor-element.elementor-element-e5a8d79
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 15px;
}
.elementor-7
  .elementor-element.elementor-element-e5a8d79
  > .elementor-element-populated {
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
}
.elementor-7 .elementor-element.elementor-element-376fe0f {
  font-weight: 400;
}
.elementor-7
  .elementor-element.elementor-element-376fe0f
  > .elementor-widget-container {
  padding: 5px 0px 0px 0px;
}
.elementor-7 .elementor-element.elementor-element-016c6ad {
  font-weight: 400;
}
.elementor-7 .elementor-element.elementor-element-5be8ba5 .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
}
.elementor-7
  .elementor-element.elementor-element-5be8ba5
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-1b6ab50
  > .elementor-container {
  max-width: 1200px;
}
.elementor-7
  .elementor-element.elementor-element-1b6ab50:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-7
  .elementor-element.elementor-element-1b6ab50
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #eeecec;
}
.elementor-7 .elementor-element.elementor-element-1b6ab50 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 80px 0px 120px 0px;
}
.elementor-7
  .elementor-element.elementor-element-1b6ab50
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-3295f92 {
  text-align: center;
}
.elementor-7
  .elementor-element.elementor-element-1fe4b07
  > .elementor-container {
  max-width: 1109px;
}
.elementor-7 .elementor-element.elementor-element-1fe4b07 {
  padding: 20px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 20px;
}
.elementor-7
  .elementor-element.elementor-element-8a1a06d:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-background-slideshow {
  border-radius: 5px 5px 5px 5px;
}
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.13);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 21px 0px 21px;
  --e-column-margin-right: 21px;
  --e-column-margin-left: 21px;
  padding: 40px 20px 40px 20px;
}
.elementor-7
  .elementor-element.elementor-element-8a1a06d
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-af38e60.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-7
  .elementor-element.elementor-element-af38e60.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-7
  .elementor-element.elementor-element-af38e60.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-7
  .elementor-element.elementor-element-af38e60
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 100%;
}
.elementor-7
  .elementor-element.elementor-element-af38e60
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-af38e60
  .elementor-image-box-title {
  margin-bottom: 10px;
  font-weight: 700;
}
.elementor-7
  .elementor-element.elementor-element-af38e60
  .elementor-image-box-description {
  color: #505050;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-7 .elementor-element.elementor-element-7104ab3 .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;
  fill: #018efe;
  color: #018efe;
  background-color: #ffffff00;
  border-radius: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 20px;
}
.elementor-7
  .elementor-element.elementor-element-af00026:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-background-slideshow {
  border-radius: 5px 5px 5px 5px;
}
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.13);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 21px 0px 21px;
  --e-column-margin-right: 21px;
  --e-column-margin-left: 21px;
  padding: 40px 20px 40px 20px;
}
.elementor-7
  .elementor-element.elementor-element-af00026
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 100%;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb
  .elementor-image-box-title {
  margin-bottom: 10px;
  font-weight: 700;
}
.elementor-7
  .elementor-element.elementor-element-991e5cb
  .elementor-image-box-description {
  color: #505050;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-7 .elementor-element.elementor-element-3d97ce1 .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;
  fill: #018efe;
  color: #018efe;
  background-color: #ffffff00;
  border-radius: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 20px;
}
.elementor-7
  .elementor-element.elementor-element-29ec78e:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-background-slideshow {
  border-radius: 5px 5px 5px 5px;
}
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.13);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 21px 0px 21px;
  --e-column-margin-right: 21px;
  --e-column-margin-left: 21px;
  padding: 40px 20px 40px 20px;
}
.elementor-7
  .elementor-element.elementor-element-29ec78e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 100%;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b
  .elementor-image-box-title {
  margin-bottom: 10px;
  font-weight: 700;
}
.elementor-7
  .elementor-element.elementor-element-1627f4b
  .elementor-image-box-description {
  color: #505050;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-7 .elementor-element.elementor-element-362f721 .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;
  fill: #018efe;
  color: #018efe;
  background-color: #ffffff00;
  border-radius: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-3feecdd:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-3feecdd
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url('../public/img/industries.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-7
  .elementor-element.elementor-element-3feecdd
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-3feecdd
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-3140dd0 {
  --spacer-size: 350px;
}
.elementor-7
  .elementor-element.elementor-element-3ca8376
  > .elementor-element-populated {
  padding: 78px 140px 100px 60px;
}
.elementor-7
  .elementor-element.elementor-element-2b08e2e
  .elementor-heading-title {
  color: #00002d;
  font-family: 'Poppins', Sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}
.elementor-7 .elementor-element.elementor-element-9442473 {
  font-weight: 400;
}
.elementor-7 .elementor-element.elementor-element-92d32bd {
  --divider-border-style: solid;
  --divider-color: #dcdcdc;
  --divider-border-width: 1px;
}
.elementor-7
  .elementor-element.elementor-element-92d32bd
  .elementor-divider-separator {
  width: 100%;
}
.elementor-7
  .elementor-element.elementor-element-92d32bd
  > .elementor-widget-container {
  padding: 5px 0px 25px 0px;
}
.elementor-7
  .elementor-element.elementor-element-e2c4507
  .elementor-heading-title {
  color: #00002d;
  font-family: 'Poppins', Sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(15px / 2);
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(15px / 2);
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(15px / 2);
  margin-left: calc(15px / 2);
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-15px / 2);
  margin-left: calc(-15px / 2);
}
body.rtl
  .elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-15px / 2);
}
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-15px / 2);
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-icon
  i {
  color: #018efe;
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-icon
  svg {
  fill: #018efe;
}
.elementor-7 .elementor-element.elementor-element-9ba6eea {
  --e-icon-list-icon-size: 20px;
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  .elementor-icon-list-item
  > a {
  font-weight: 400;
}
.elementor-7
  .elementor-element.elementor-element-9ba6eea
  > .elementor-widget-container {
  padding: 5px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-2f301c1
  > .elementor-container {
  max-width: 1200px;
}
.elementor-7
  .elementor-element.elementor-element-2f301c1:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-7
  .elementor-element.elementor-element-2f301c1
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #eeecec;
}
.elementor-7 .elementor-element.elementor-element-2f301c1 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 80px 0px 85px 0px;
}
.elementor-7
  .elementor-element.elementor-element-2f301c1
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-4c5d6d3 {
  text-align: center;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-posts-container
  .elementor-post__thumbnail {
  padding-bottom: calc(0.66 * 100%);
}
.elementor-7 .elementor-element.elementor-element-8583c67:after {
  content: '0.66';
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__thumbnail__link {
  width: 100%;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__meta-data
  span
  + span:before {
  content: '|';
}
.elementor-7 .elementor-element.elementor-element-8583c67 {
  --grid-column-gap: 30px;
  --grid-row-gap: 35px;
}
.elementor-7 .elementor-element.elementor-element-8583c67 .elementor-post {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 0px;
  background-color: #ffffff;
  border-color: #bee0f9;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__text {
  padding: 0px 20px 40px 20px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67.elementor-posts--thumbnail-left
  .elementor-post__thumbnail__link {
  margin-right: 20px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67.elementor-posts--thumbnail-right
  .elementor-post__thumbnail__link {
  margin-left: 20px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67.elementor-posts--thumbnail-top
  .elementor-post__thumbnail__link {
  margin-bottom: 20px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__title,
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__title
  a {
  color: #00002d;
  font-family: 'Poppins', Sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__title {
  margin-bottom: 10px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__meta-data {
  color: #ffffff;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__excerpt
  p {
  color: #505050;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__excerpt {
  margin-bottom: 20px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  .elementor-post__read-more {
  color: #018efe;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  a.elementor-post__read-more {
  font-family: 'Poppins', Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 14px;
}
.elementor-7
  .elementor-element.elementor-element-8583c67
  > .elementor-widget-container {
  margin: 10px 0px 0px 0px;
}
.elementor-7 .elementor-element.elementor-element-c18f64a .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
}
.elementor-7
  .elementor-element.elementor-element-c18f64a
  > .elementor-widget-container {
  margin: 30px 0px 0px 0px;
}
.elementor-7
  .elementor-element.elementor-element-de3ee80
  > .elementor-container {
  max-width: 1200px;
}
.elementor-7
  .elementor-element.elementor-element-de3ee80:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-7
  .elementor-element.elementor-element-de3ee80
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url('../public/img/video-section.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-7
  .elementor-element.elementor-element-de3ee80
  > .elementor-background-overlay {
  background-color: #002247;
  opacity: 0.6;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-de3ee80 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 190px 0px 220px 0px;
}
.elementor-7
  .elementor-element.elementor-element-af23cf9
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 5px;
}
.elementor-7
  .elementor-element.elementor-element-97bbb34
  .elementor-heading-title {
  color: #ffffff;
  font-family: 'Poppins', Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
}
.elementor-7
  .elementor-element.elementor-element-13d0690
  .elementor-heading-title {
  color: #ffffff;
  font-family: 'Poppins', Sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 65px;
}
.elementor-7
  .elementor-element.elementor-element-b6ed4d8
  > .elementor-container {
  max-width: 1200px;
}
.elementor-7 .elementor-element.elementor-element-b6ed4d8 {
  padding: 105px 0px 105px 0px;
  z-index: 1;
}
.elementor-7
  .elementor-element.elementor-element-d3e5348
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 10px 10px 67px 10px;
}
.elementor-7
  .elementor-element.elementor-element-d3e5348
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-9c8e1b0 {
  text-align: center;
}
.elementor-7 .elementor-element.elementor-element-1797c34 {
  width: auto;
  max-width: auto;
  top: 84.997px;
  z-index: 11;
}
body:not(.rtl) .elementor-7 .elementor-element.elementor-element-1797c34 {
  left: 135.993px;
}
body.rtl .elementor-7 .elementor-element.elementor-element-1797c34 {
  right: 135.993px;
}
.elementor-7 .elementor-element.elementor-element-a3ea6d9 {
  width: auto;
  max-width: auto;
  top: 158px;
}
body:not(.rtl) .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
  left: 180px;
}
body.rtl .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
  right: 180px;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-arrows-yes
  .elementor-main-swiper {
  width: calc(838px - 40px);
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-main-swiper {
  width: 838px;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-main-swiper
  .swiper-slide {
  background-color: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-color: #cce4ff;
  padding: 35px 80px 55px 80px;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-testimonial__text {
  color: #505050;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-testimonial__name {
  color: #018efe;
  font-family: 'Poppins', Sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-testimonial__title {
  color: #505050;
  font-family: 'Manrope', Sans-serif;
  font-weight: 400;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-testimonial__image
  img {
  width: 100px;
  height: 100px;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_left
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_right
  .elementor-testimonial__content:after {
  top: calc(20px + (100px / 2) - 8px);
}
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_stacked:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-right)
  .elementor-testimonial__content:after,
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_inline:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-right)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-left
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_inline.elementor-testimonial--align-left
  .elementor-testimonial__content:after {
  left: calc(20px + (100px / 2) - 8px);
  right: auto;
}
body.rtl
  .elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_stacked:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-left)
  .elementor-testimonial__content:after,
body.rtl
  .elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_inline:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-left)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-right
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_inline.elementor-testimonial--align-right
  .elementor-testimonial__content:after {
  right: calc(20px + (100px / 2) - 8px);
  left: auto;
}
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_above:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-right)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_above.elementor-testimonial--align-left
  .elementor-testimonial__content:after {
  left: calc(20px + (100px / 2) - 8px);
  right: auto;
}
body.rtl
  .elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_above:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-left)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-ea8f294.elementor-testimonial--layout-image_above.elementor-testimonial--align-right
  .elementor-testimonial__content:after {
  right: calc(20px + (100px / 2) - 8px);
  left: auto;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-swiper-button {
  font-size: 30px;
  color: #918f8f;
}
.elementor-7
  .elementor-element.elementor-element-ea8f294
  .elementor-swiper-button
  svg {
  fill: #918f8f;
}
.elementor-7
  .elementor-element.elementor-element-2732ca6
  > .elementor-container {
  max-width: 1200px;
}
.elementor-7 .elementor-element.elementor-element-2732ca6 {
  padding: 105px 0px 105px 0px;
  z-index: 1;
}
.elementor-7
  .elementor-element.elementor-element-c571e01
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 10px 10px 67px 10px;
}
.elementor-7
  .elementor-element.elementor-element-c571e01
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7 .elementor-element.elementor-element-38f2d3b {
  text-align: center;
}
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-arrows-yes
  .elementor-main-swiper {
  width: calc(76% - 40px);
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-main-swiper {
  width: 76%;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-main-swiper
  .swiper-slide {
  background-color: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-color: #cce4ff;
  padding: 35px 80px 55px 80px;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-testimonial__text {
  color: #505050;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-testimonial__name {
  color: #018efe;
  font-family: 'Poppins', Sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-testimonial__title {
  color: #505050;
  font-family: 'Manrope', Sans-serif;
  font-weight: 400;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-testimonial__image
  img {
  width: 100px;
  height: 100px;
}
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_left
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_right
  .elementor-testimonial__content:after {
  top: calc(20px + (100px / 2) - 8px);
}
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_stacked:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-right)
  .elementor-testimonial__content:after,
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_inline:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-right)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-left
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_inline.elementor-testimonial--align-left
  .elementor-testimonial__content:after {
  left: calc(20px + (100px / 2) - 8px);
  right: auto;
}
body.rtl
  .elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_stacked:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-left)
  .elementor-testimonial__content:after,
body.rtl
  .elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_inline:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-left)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-right
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_inline.elementor-testimonial--align-right
  .elementor-testimonial__content:after {
  right: calc(20px + (100px / 2) - 8px);
  left: auto;
}
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_above:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-right)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_above.elementor-testimonial--align-left
  .elementor-testimonial__content:after {
  left: calc(20px + (100px / 2) - 8px);
  right: auto;
}
body.rtl
  .elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_above:not(
    .elementor-testimonial--align-center
  ):not(.elementor-testimonial--align-left)
  .elementor-testimonial__content:after,
.elementor-7
  .elementor-element.elementor-element-585a683.elementor-testimonial--layout-image_above.elementor-testimonial--align-right
  .elementor-testimonial__content:after {
  right: calc(20px + (100px / 2) - 8px);
  left: auto;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-swiper-button {
  font-size: 30px;
  color: #918f8f;
}
.elementor-7
  .elementor-element.elementor-element-585a683
  .elementor-swiper-button
  svg {
  fill: #918f8f;
}
.elementor-7
  .elementor-element.elementor-element-f7642bc
  > .elementor-container {
  max-width: 715px;
}
.elementor-7
  .elementor-element.elementor-element-f7642bc:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-7
  .elementor-element.elementor-element-f7642bc
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #eeecec;
}
.elementor-7 .elementor-element.elementor-element-f7642bc {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 85px 0px 80px 0px;
}
.elementor-7
  .elementor-element.elementor-element-f7642bc
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-7
  .elementor-element.elementor-element-c09ef43
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 10px;
}
.elementor-7 .elementor-element.elementor-element-e4016e2 {
  text-align: center;
}
.elementor-7 .elementor-element.elementor-element-d0b729f {
  text-align: center;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group {
  padding-right: calc(10px / 2);
  padding-left: calc(10px / 2);
  margin-bottom: 10px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-form-fields-wrapper {
  margin-left: calc(-10px / 2);
  margin-right: calc(-10px / 2);
  margin-bottom: -10px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group.recaptcha_v3-bottomleft,
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group.recaptcha_v3-bottomright {
  margin-bottom: 0;
}
body.rtl
  .elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-labels-inline
  .elementor-field-group
  > label {
  padding-left: 0px;
}
body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-labels-inline
  .elementor-field-group
  > label {
  padding-right: 0px;
}
body
  .elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-labels-above
  .elementor-field-group
  > label {
  padding-bottom: 0px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group
  > label,
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-subgroup
  label {
  color: #505050;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-type-html {
  padding-bottom: 0px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group
  .elementor-field {
  color: #505050;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group
  .elementor-field,
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-subgroup
  label {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group:not(.elementor-field-type-upload)
  .elementor-field:not(.elementor-select-wrapper) {
  background-color: #ffffff;
  border-color: #d0dedd;
  border-width: 1px 0px 1px 1px;
  border-radius: 100px 0px 0px 100px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group
  .elementor-select-wrapper
  select {
  background-color: #ffffff;
  border-color: #d0dedd;
  border-width: 1px 0px 1px 1px;
  border-radius: 100px 0px 0px 100px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-field-group
  .elementor-select-wrapper::before {
  color: #d0dedd;
}
.elementor-7 .elementor-element.elementor-element-1418c78 .elementor-button {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
  border-radius: 100px 100px 100px 100px;
  padding: 20px 35px 20px 35px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .e-form__buttons__wrapper__button-next {
  background-color: #58b5a6;
  color: #00002d;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-button[type='submit'] {
  background-color: #58b5a6;
  color: #00002d;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-button[type='submit']
  svg
  * {
  fill: #00002d;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .e-form__buttons__wrapper__button-previous {
  background-color: #58b5a6;
  color: #00002d;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .e-form__buttons__wrapper__button-next:hover {
  background-color: #00002d;
  color: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-button[type='submit']:hover {
  background-color: #00002d;
  color: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .elementor-button[type='submit']:hover
  svg
  * {
  fill: #ffffff;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  .e-form__buttons__wrapper__button-previous:hover {
  background-color: #00002d;
  color: #ffffff;
}
.elementor-7 .elementor-element.elementor-element-1418c78 {
  --e-form-steps-indicators-spacing: 20px;
  --e-form-steps-indicator-padding: 30px;
  --e-form-steps-indicator-inactive-secondary-color: #ffffff;
  --e-form-steps-indicator-active-secondary-color: #ffffff;
  --e-form-steps-indicator-completed-secondary-color: #ffffff;
  --e-form-steps-divider-width: 1px;
  --e-form-steps-divider-gap: 10px;
}
.elementor-7
  .elementor-element.elementor-element-1418c78
  > .elementor-widget-container {
  padding: 15px 0px 0px 0px;
}
@media (max-width: 1024px) {
  .elementor-7
    .elementor-element.elementor-element-914c960
    > .elementor-element-populated {
    padding: 70px 15px 110px 15px;
  }
  .elementor-7
    .elementor-element.elementor-element-9618e35
    > .elementor-element-populated {
    padding: 10px 10px 10px 10px;
  }
  .elementor-7 .elementor-element.elementor-element-efd8687 {
    --spacer-size: 760px;
  }
  .elementor-7 .elementor-element.elementor-element-1930e64 {
    text-align: center;
  }
  .elementor-7
    .elementor-element.elementor-element-e5a8d79
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-7
    .elementor-element.elementor-element-8a1a06d
    > .elementor-element-populated {
    margin: 0px 21px 0px 5px;
    --e-column-margin-right: 21px;
    --e-column-margin-left: 5px;
  }
  .elementor-7
    .elementor-element.elementor-element-af00026
    > .elementor-element-populated {
    margin: 0px 5px 0px 21px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 21px;
  }
  .elementor-7
    .elementor-element.elementor-element-29ec78e
    > .elementor-element-populated {
    margin: 40px 21px 0px 5px;
    --e-column-margin-right: 21px;
    --e-column-margin-left: 5px;
  }
  .elementor-7 .elementor-element.elementor-element-3140dd0 {
    --spacer-size: 800px;
  }
  .elementor-7
    .elementor-element.elementor-element-3ca8376
    > .elementor-element-populated {
    padding: 78px 15px 100px 15px;
  }
  .elementor-7
    .elementor-element.elementor-element-8583c67
    .elementor-posts-container
    .elementor-post__thumbnail {
    padding-bottom: calc(0.67 * 100%);
  }
  .elementor-7 .elementor-element.elementor-element-8583c67:after {
    content: '0.67';
  }
  .elementor-7
    .elementor-element.elementor-element-13d0690
    .elementor-heading-title {
    line-height: 55px;
  }
  .elementor-7
    .elementor-element.elementor-element-d3e5348
    > .elementor-element-populated {
    margin: 0px 15px 0px 15px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 15px;
    padding: 20px 40px 80px 40px;
  }
  body:not(.rtl) .elementor-7 .elementor-element.elementor-element-1797c34 {
    left: -6.016px;
  }
  body.rtl .elementor-7 .elementor-element.elementor-element-1797c34 {
    right: -6.016px;
  }
  .elementor-7 .elementor-element.elementor-element-1797c34 {
    top: 94.984px;
  }
  .elementor-7 .elementor-element.elementor-element-a3ea6d9 img {
    width: 89%;
    height: 365px;
  }
  body:not(.rtl) .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
    left: -17px;
  }
  body.rtl .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
    right: -17px;
  }
  .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
    top: 190px;
  }
  .elementor-7
    .elementor-element.elementor-element-ea8f294.elementor-arrows-yes
    .elementor-main-swiper {
    width: calc(681px - 40px);
  }
  .elementor-7
    .elementor-element.elementor-element-ea8f294
    .elementor-main-swiper {
    width: 681px;
  }
  .elementor-7
    .elementor-element.elementor-element-c571e01
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 80px 10px;
  }
  .elementor-7
    .elementor-element.elementor-element-585a683.elementor-arrows-yes
    .elementor-main-swiper {
    width: calc(89% - 40px);
  }
  .elementor-7
    .elementor-element.elementor-element-585a683
    .elementor-main-swiper {
    width: 89%;
  }
}
@media (min-width: 768px) {
  .elementor-7 .elementor-element.elementor-element-914c960 {
    width: 53.691%;
  }
  .elementor-7 .elementor-element.elementor-element-9618e35 {
    width: 46.269%;
  }
  .elementor-7 .elementor-element.elementor-element-af23cf9 {
    width: 66.833%;
  }
  .elementor-7 .elementor-element.elementor-element-a4decc7 {
    width: 33.084%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-7 .elementor-element.elementor-element-914c960 {
    width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-9618e35 {
    width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-fd7b283 {
    width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-e5a8d79 {
    width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-8a1a06d {
    width: 50%;
  }
  .elementor-7 .elementor-element.elementor-element-af00026 {
    width: 50%;
  }
  .elementor-7 .elementor-element.elementor-element-29ec78e {
    width: 50%;
  }
  .elementor-7 .elementor-element.elementor-element-3feecdd {
    width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-3ca8376 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-7
    .elementor-element.elementor-element-7c29f3b
    > .elementor-background-overlay {
    background-size: 250px auto;
  }
  .elementor-7
    .elementor-element.elementor-element-e7802e9
    .elementor-heading-title {
    font-size: 40px;
    line-height: 50px;
  }
  .elementor-7 .elementor-element.elementor-element-3d429ba {
    width: 100%;
    max-width: 100%;
  }
  .elementor-7
    .elementor-element.elementor-element-0aa6bab
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-7 .elementor-element.elementor-element-0aa6bab {
    width: 100%;
    max-width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-efd8687 {
    --spacer-size: 250px;
  }
  .elementor-7 .elementor-element.elementor-element-5be8ba5 {
    width: 100%;
    max-width: 100%;
  }
  .elementor-7
    .elementor-element.elementor-element-8a1a06d
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 40px 15px 40px 15px;
  }
  .elementor-7
    .elementor-element.elementor-element-af38e60
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-7
    .elementor-element.elementor-element-af00026
    > .elementor-element-populated {
    margin: 40px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 40px 15px 40px 15px;
  }
  .elementor-7
    .elementor-element.elementor-element-991e5cb
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-7
    .elementor-element.elementor-element-29ec78e
    > .elementor-element-populated {
    margin: 40px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 40px 15px 40px 15px;
  }
  .elementor-7
    .elementor-element.elementor-element-1627f4b
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-7 .elementor-element.elementor-element-3140dd0 {
    --spacer-size: 350px;
  }
  .elementor-7
    .elementor-element.elementor-element-2b08e2e
    .elementor-heading-title {
    font-size: 35px;
    line-height: 45px;
  }
  .elementor-7
    .elementor-element.elementor-element-8583c67
    .elementor-posts-container
    .elementor-post__thumbnail {
    padding-bottom: calc(0.67 * 100%);
  }
  .elementor-7 .elementor-element.elementor-element-8583c67:after {
    content: '0.67';
  }
  .elementor-7
    .elementor-element.elementor-element-8583c67
    .elementor-post__thumbnail__link {
    width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-c18f64a {
    width: 100%;
    max-width: 100%;
  }
  .elementor-7 .elementor-element.elementor-element-de3ee80 {
    padding: 90px 0px 90px 0px;
  }
  .elementor-7 .elementor-element.elementor-element-97bbb34 {
    text-align: center;
  }
  .elementor-7 .elementor-element.elementor-element-13d0690 {
    text-align: center;
  }
  .elementor-7
    .elementor-element.elementor-element-13d0690
    .elementor-heading-title {
    font-size: 30px;
    line-height: 40px;
  }
  .elementor-7
    .elementor-element.elementor-element-d3e5348
    > .elementor-element-populated {
    margin: 0px 20px 60px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-7 .elementor-element.elementor-element-1797c34 img {
    width: 77%;
  }
  .elementor-7 .elementor-element.elementor-element-1797c34 {
    width: auto;
    max-width: auto;
    top: 134.99px;
  }
  body:not(.rtl) .elementor-7 .elementor-element.elementor-element-1797c34 {
    left: -38px;
  }
  body.rtl .elementor-7 .elementor-element.elementor-element-1797c34 {
    right: -38px;
  }
  .elementor-7 .elementor-element.elementor-element-a3ea6d9 img {
    height: 500px;
  }
  body:not(.rtl) .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
    left: -21px;
  }
  body.rtl .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
    right: -21px;
  }
  .elementor-7 .elementor-element.elementor-element-a3ea6d9 {
    top: 215px;
  }
  .elementor-7
    .elementor-element.elementor-element-ea8f294.elementor-arrows-yes
    .elementor-main-swiper {
    width: calc(324px - 40px);
  }
  .elementor-7
    .elementor-element.elementor-element-ea8f294
    .elementor-main-swiper {
    width: 324px;
  }
  .elementor-7
    .elementor-element.elementor-element-ea8f294
    > .elementor-widget-container {
    padding: 0px 0px 70px 0px;
  }
  .elementor-7
    .elementor-element.elementor-element-c571e01
    > .elementor-element-populated {
    margin: 0px 0px 60px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-7
    .elementor-element.elementor-element-38f2d3b
    > .elementor-widget-container {
    padding: 0px 15px 0px 15px;
  }
  .elementor-7
    .elementor-element.elementor-element-585a683.elementor-arrows-yes
    .elementor-main-swiper {
    width: calc(100% - 40px);
  }
  .elementor-7
    .elementor-element.elementor-element-585a683
    .elementor-main-swiper {
    width: 100%;
  }
  .elementor-7
    .elementor-element.elementor-element-585a683
    > .elementor-widget-container {
    padding: 0px 0px 70px 0px;
  }
  .elementor-7
    .elementor-element.elementor-element-1418c78
    > .elementor-widget-container {
    margin: 0px 15px 0px 0px;
  }
}
.elementor-9
  .elementor-element.elementor-element-b4adeac
  > .elementor-container {
  max-width: 1200px;
}
.elementor-9 .elementor-element.elementor-element-b4adeac {
  padding: 10px 0px 10px 0px;
}
.elementor-bc-flex-widget
  .elementor-9
  .elementor-element.elementor-element-c969434.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-9
  .elementor-element.elementor-element-c969434.elementor-column.elementor-element[data-element_type='column']
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-9 .elementor-element.elementor-element-f5e282f {
  text-align: left;
}
.elementor-bc-flex-widget
  .elementor-9
  .elementor-element.elementor-element-bd89919.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-9
  .elementor-element.elementor-element-bd89919.elementor-column.elementor-element[data-element_type='column']
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-menu-toggle {
  margin-left: auto;
  background-color: #ffffff00;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu
  .elementor-item {
  font-family: 'Poppins', Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main
  .elementor-item {
  color: #00002d;
  fill: #00002d;
  padding-left: 0px;
  padding-right: 0px;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main
  .elementor-item:hover,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main
  .elementor-item.highlighted,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main
  .elementor-item:focus {
  color: #00002d;
  fill: #00002d;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active {
  color: #00002d;
}
.elementor-9 .elementor-element.elementor-element-0d5a7fa {
  --e-nav-menu-horizontal-menu-item-margin: calc(30px / 2);
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
  .elementor-nav-menu
  > li:not(:last-child) {
  margin-bottom: 30px;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-menu-toggle {
  color: #00002d;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown {
  background-color: #ffffff;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a.highlighted,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-menu-toggle:hover {
  color: #020202;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a.highlighted {
  background-color: #018efe;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  a.elementor-item-active {
  color: #020202;
  background-color: #018efe;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  .elementor-item,
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-nav-menu--dropdown
  .elementor-sub-item {
  font-family: 'Poppins', Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  div.elementor-menu-toggle {
  color: #018efe;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  div.elementor-menu-toggle
  svg {
  fill: #018efe;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  div.elementor-menu-toggle:hover {
  color: #018efe;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  div.elementor-menu-toggle:hover
  svg {
  fill: #018efe;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  .elementor-menu-toggle:hover {
  background-color: #ffffff00;
}
.elementor-9
  .elementor-element.elementor-element-0d5a7fa
  > .elementor-widget-container {
  padding: 0px 15px 0px 0px;
}
@media (max-width: 1024px) {
  .elementor-9
    .elementor-element.elementor-element-0d5a7fa
    .elementor-nav-menu--dropdown
    .elementor-item,
  .elementor-9
    .elementor-element.elementor-element-0d5a7fa
    .elementor-nav-menu--dropdown
    .elementor-sub-item {
    font-size: 18px;
  }
  .elementor-9
    .elementor-element.elementor-element-0d5a7fa
    .elementor-nav-menu--main
    > .elementor-nav-menu
    > li
    > .elementor-nav-menu--dropdown,
  .elementor-9
    .elementor-element.elementor-element-0d5a7fa
    .elementor-nav-menu__container.elementor-nav-menu--dropdown {
    margin-top: 19px !important;
  }
  .elementor-9 .elementor-element.elementor-element-0d5a7fa {
    --nav-menu-icon-size: 35px;
  }
  .elementor-9
    .elementor-element.elementor-element-0d5a7fa
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
}
@media (min-width: 768px) {
  .elementor-9 .elementor-element.elementor-element-c969434 {
    width: 20%;
  }
  .elementor-9 .elementor-element.elementor-element-bd89919 {
    width: 80%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-9 .elementor-element.elementor-element-c969434 {
    width: 50%;
  }
  .elementor-9 .elementor-element.elementor-element-bd89919 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .elementor-9 .elementor-element.elementor-element-c969434 {
    width: 50%;
  }
  .elementor-9 .elementor-element.elementor-element-bd89919 {
    width: 50%;
  }
}

.elementor-35
  .elementor-element.elementor-element-c8dc900:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-35
  .elementor-element.elementor-element-c8dc900
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #00002d;
}
.elementor-35 .elementor-element.elementor-element-c8dc900 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 60px 0px 45px 0px;
}
.elementor-35
  .elementor-element.elementor-element-c8dc900
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(25px / 2);
  margin-left: calc(25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-25px / 2);
  margin-left: calc(-25px / 2);
}
body.rtl
  .elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-25px / 2);
}
body:not(.rtl)
  .elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-item:not(:last-child):after {
  content: '';
  height: 50%;
  border-color: #b1bdc9;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child):after {
  border-top-style: solid;
  border-top-width: 2px;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:not(:last-child):after {
  border-left-style: solid;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-inline-items
  .elementor-icon-list-item:not(:last-child):after {
  border-left-width: 2px;
}
.elementor-35 .elementor-element.elementor-element-8281db4 {
  --e-icon-list-icon-size: 14px;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-text {
  color: #b1bdc9;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-35
  .elementor-element.elementor-element-8281db4
  .elementor-icon-list-item
  > a {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-35
  .elementor-element.elementor-element-8281db4
  > .elementor-widget-container {
  padding: 10px 0px 0px 0px;
}
.elementor-35
  .elementor-element.elementor-element-c743ba9
  > .elementor-container {
  max-width: 1410px;
}
.elementor-35
  .elementor-element.elementor-element-c743ba9:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-35
  .elementor-element.elementor-element-c743ba9
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #00002d;
}
.elementor-35 .elementor-element.elementor-element-c743ba9 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0px 0px 20px 0px;
}
.elementor-35
  .elementor-element.elementor-element-c743ba9
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .elementor-35
  .elementor-element.elementor-element-22ae2b9.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-35
  .elementor-element.elementor-element-22ae2b9.elementor-column.elementor-element[data-element_type='column']
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-35
  .elementor-element.elementor-element-22ae2b9.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}
.elementor-35
  .elementor-element.elementor-element-22ae2b9
  > .elementor-element-populated {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #333333;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 10px 10px 10px;
}
.elementor-35
  .elementor-element.elementor-element-22ae2b9
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-35 .elementor-element.elementor-element-bc723cb {
  color: #b1bdc9;
  font-family: 'Poppins', Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  width: auto;
  max-width: auto;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(25px / 2);
  margin-left: calc(25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-25px / 2);
  margin-left: calc(-25px / 2);
}
body.rtl
  .elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-25px / 2);
}
body:not(.rtl)
  .elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-25px / 2);
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-item:not(:last-child):after {
  content: '';
  height: 50%;
  border-color: #b1bdc9;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child):after {
  border-top-style: solid;
  border-top-width: 2px;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:not(:last-child):after {
  border-left-style: solid;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-inline-items
  .elementor-icon-list-item:not(:last-child):after {
  border-left-width: 2px;
}
.elementor-35 .elementor-element.elementor-element-1549e30 {
  --e-icon-list-icon-size: 14px;
  width: auto;
  max-width: auto;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-text {
  color: #b1bdc9;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-35
  .elementor-element.elementor-element-1549e30
  .elementor-icon-list-item
  > a {
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.elementor-35
  .elementor-element.elementor-element-1549e30
  > .elementor-widget-container {
  padding: 0px 0px 0px 8px;
}
@media (max-width: 767px) {
  .elementor-bc-flex-widget
    .elementor-35
    .elementor-element.elementor-element-22ae2b9.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-35
    .elementor-element.elementor-element-22ae2b9.elementor-column.elementor-element[data-element_type='column']
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-35
    .elementor-element.elementor-element-22ae2b9.elementor-column
    > .elementor-widget-wrap {
    justify-content: center;
  }
  .elementor-35 .elementor-element.elementor-element-bc723cb {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .elementor-35 .elementor-element.elementor-element-1549e30 {
    width: 100%;
    max-width: 100%;
  }
}
